// Typescript enum for severity of an issue
export enum IssueSeverity {
    Error = 'error',
    Warning = 'warning',
    Info = 'info',
}

// Typescript structure for an issue
export interface Issue {
    severity: IssueSeverity;
    message: string;
}