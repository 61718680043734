import * as React from 'react'
import { fonts, styles } from "../data/StyleData.js"
import { v4 as uuid } from 'uuid';
import { DocumentYapUI } from '../yap-ui/DocumentYapUI.js'

const AppStateContext = React.createContext()

function useAppState() {
    const context = React.useContext(AppStateContext)
    if (!context) {
            return null
          //throw new Error(`useAppState must be used within a PolymerStateProvider`)
    }
    return context
}

function sectionsFromTemplate(template) {
    return template.components().map( (item) => {
        var canDelete = true
        var canMove = true
        
        if (item.canDelete != null) {
            canDelete = item.canDelete
        }
        if (item.canMove != null) {
            canMove = item.canMove
        }
        var component = item
        if (item.component != null) {
            component = item.component
        }
        return {     
            id: uuid(),
            group: item.group,
            align: item.align,
            type: component.type,
            canDelete: canDelete,
            canMove: canMove,
            props: component.defaultProps()
        }
    })
}

function ContentBuilderStateProvider(props) {

    var { pages, onPagesUpdated, yapuiJSON, onYapUIJSONUpdated, pageRenderer } = props

    var emptyDocument = pages == null || pages.length == 0

    const createEmptyPage = (title) => {
        return {
            'id' : uuid(),
            'sections' : [],
            'style': 'default',
            'title': title ?? 'New Page',        
        }
    }

    /**
     * Setup Default Page (If needed)
     */
    if (emptyDocument) {
        console.log('empty document')
        //pages = [createEmptyPage()]

        if (props.defaultTemplate) {
            var pages = [createEmptyPage()]
            pages[0].sections = sectionsFromTemplate(props.defaultTemplate)
            setTimeout( () => {
                onPagesUpdated(pages, false, { })
            }, 10)
        }
    }
    

    var currentPage = pages ? pages[0] : null

    const currentPageRef = React.useRef()
    
    const pagesRef = React.useRef()
    pagesRef.current = pages

    const [selectedId, setSelectedId] = React.useState(0)
    const [selectedPageId, setSelectedPageId] = React.useState(currentPage ? currentPage.id : null)
    const [highlightedSection, setHighlightedSection] = React.useState(0)
    
    const [inspectorModel, setInspectorModel] = React.useState(null)
    const inspectorRef = React.useRef(null)

    const [showAddSection, setShowAddSection] = React.useState(false)
    const [showAssetLibrary, setShowAssetLibrary] = React.useState(false)
    const [showAssetEditModal, setShowAssetEditModal] = React.useState(null)
    const [assetLibraryTypes, setAssetLibraryTypes] = React.useState(null)
    const [showTemplateLibrary, setShowTemplateLibrary] = React.useState(emptyDocument && props.templates != null && props.templates.length > 0)
    const [sidebarState, setSidebarState ] = React.useState("default")
    const [showSidebar, setShowSidebar] = React.useState(false)
    const [validationEnabled, setValidationEnabled ] = React.useState(false)
    const [showComponentLibrary, setShowComponentLibrary] = React.useState(false)
    
    const [contentChanged, setContentChanged] = React.useState(false)
    const [insertSectionIndex, setInsertSectionIndex] = React.useState(null)
    const [insertSectionGroup, setInsertSectionGroup] = React.useState(null)
    const [showInsertSection, setShowInsertSection] = React.useState(false)

    const onAssetSelected = React.useRef( () => { })
    const dragData = React.useRef(null)

    const templateLibraryRef = React.useRef({ mode: 'update'})

    const refreshInspector = (model) => {
        setSelectedId(selectedId)
        if (model) {
            setInspectorModel({ ...model })
        }
    }

    React.useEffect(() => {

        const handlePress = (e) => {
            switch (e.key) {
                case "v":
                    //setValidationEnabled(!validationEnabled)
                    break
                default:
                    break
            }
        }

        window.addEventListener('keyup', handlePress);
        
        return () => {
             window.removeEventListener('keyup', handlePress)
        }

    }, [validationEnabled]);

     /**
     * Find current page
     */
    (pages ?? []).forEach( (page, idx) => {
        if (page.id == selectedPageId) {
            currentPage = page
        }
    })
    currentPageRef.current = currentPage

    React.useEffect( () => {    
        setSelected(null)
    }, [selectedPageId])

    React.useEffect( () => {
        if (props.onSelectedPageId && currentPageRef.current )  {
            props.onSelectedPageId(currentPageRef.current.id)
        }
        
    }, [currentPageRef.current ])

    const onAddPage = () => {
        let id = uuid()
        templateLibraryRef.current.mode = 'add'
        setShowTemplateLibrary(true)
    }

    const updateYapUI = (pages) => {
        if (onYapUIJSONUpdated != null) {
            const result = new DocumentYapUI().update(pages, props.components, pageRenderer)
            onYapUIJSONUpdated(result);
        }
    }   

    const generateYapUI = (pages) => {
        return new DocumentYapUI().update(pages, props.components, pageRenderer)
    }  

    const updatePage = (page, userUpdated) => {
        const newPages = pagesRef.current.map(existingPage => 
            existingPage.id === page.id ? page : existingPage
        );

        if (onPagesUpdated != null) {
            let yapui = generateYapUI(newPages) 
            onPagesUpdated([...newPages], userUpdated == null ? true : userUpdated, yapui)
        }

        // if (onYapUIJSONUpdated != null) {
        //     updateYapUI(newPages)
        // }

        setContentChanged(true)
    } 

    const addPage = (page, userUpdated) => {
        const newPages = pagesRef.current ? [...pagesRef.current, page] : [page]

        if (onPagesUpdated != null) {
            let yapui = generateYapUI(newPages) 
            onPagesUpdated([...newPages], userUpdated == null ? true : userUpdated, yapui   )
        }

        // if (onYapUIJSONUpdated != null) {
        //     updateYapUI(newPages)
        // }

        setSelectedPageId(page.id)
        setContentChanged(true)
    } 

    const onDeletePage = (pageId) => {
        deletePage(pageId, true)
    }

    const deletePage = (pageId, userUpdated) => {
        const newPages = pagesRef.current.filter(existingPage => 
            existingPage.id !== pageId
        );

        if (onPagesUpdated != null) {
            let yapui = generateYapUI(newPages) 
            onPagesUpdated([...newPages], userUpdated == null ? true : userUpdatedm, yapui)
        }

        // if (onYapUIJSONUpdated != null) {
        //     updateYapUI(newPages)
        // }

        setContentChanged(true)
        setSelectedPageId(newPages[0].id)
        
        setTimeout( () => {
            setSelectedPageId(newPages[0].id)
        }, 1)
    }

    // When a new template is selected
    const onTemplateSelected = (React.useRef( (template, addIndex) => {
        var newPage = true
        var page = createEmptyPage()

        // Create sections from templates
        page.sections = sectionsFromTemplate(template)

        // Apply style if specified
        if (template.style) {
            page.style = template.style
        }

        if (template.defaultTitle) {
            page.title = template.defaultTitle()
        }

        if (template.showAddSection) {
            page.showAddSection = template.showAddSection
        }

        page.addIndex = template.addIndex ? template.addIndex() : 0

        if (newPage) {
            addPage(page, true)
        } else {
            updatePage(page, false)
        }
    }))

    var selectedSectionId = null
    var selectedViewId = null
    if (inspectorModel) {
        selectedSectionId = inspectorModel.sectionId
        selectedViewId = inspectorModel.id
    }

    const currentSelectedSection = () => {
        if (selectedId) {
            var section = null
            currentPage.sections.forEach( s => {                
                if (s.id == selectedSectionId) {
                    section = s
                }
            })
            return section
        }
        return null
    } 

    const updateModel = (model) => {
        
        var sectionId = model.sectionId
        var encoder = model.sectionEncode

        // Update Page Model
        if (model.type == 'page' || selectedId == null) {   
            currentPage.props = {...currentPage.props, ...model}
            //currentPage['style'] = model.style
        }

        // Update Section Model
        let newSections = currentPage.sections.map( section => {
            if (section.id == sectionId) {
                var props = section.props
                var updateModel = model

                if (updateModel.id != null) {
                    updateModel.id = updateModel.id.replace(".rootId-" + sectionId, "")
                }
                
                props = encoder(updateModel, props)

                return {
                    ...section,
                    props: props
                }
            } else {
                return section
            }
        })

        var newPage = {...currentPage, sections: newSections}
        updatePage(newPage)

        refreshInspector(model)
    }

    function arraymove(arr, fromIndex, toIndex) {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
    }

    const moveSection = (sectionId, direction) => {
        var page = currentPage
        
        var a = page.sections
        var index = -1

        a.forEach( (section, sectionIndex) => {
            if (section.id == sectionId) {
                index = sectionIndex
            }
        })

        if (index > -1) {
            arraymove(a, index, index + direction)        
            page.sections = a
            updatePage(page)
        }

    }

    const deleteSection = (afterId, section) => {
        var page = currentPage
        var index = 0

        var newSections = page.sections
        page.sections.forEach( (section, sectionIndex) => {
            if (section.id == afterId) {
                index = sectionIndex
            }
        })

        newSections.splice(index, 1)
    
        page.sections = newSections

        updatePage(page)
    }

    const addSection = (afterId, section, index, group) => {
        var page = currentPage
        var sectionData = {
            'type' : section.type,
            'id'   : uuid(),
            'group' : group,
            'props' :  section.defaultProps ? section.defaultProps() : null
        }

        if (sectionData.group == null) {
            delete sectionData.group
        }
        
        if (section.defaultProps == null) {
            console.warn('No default props for section', section)
        }

        var newSections = page.sections ?? []
        var insertIndex = index ?? newSections.length
        
        newSections.forEach( (section, sectionIndex) => {
            if (section.props.id == afterId) {
                insertIndex = sectionIndex + 1
            }
        })

        newSections.splice(insertIndex, 0, sectionData);

        page.sections = newSections

        updatePage(page)

        //setShowSidebar(false)
    }
    
    const setSelected = (id, model, sectionId, sectionEncode, inspector) => {
        if (id) {
            setSelectedId(id)
            setInspectorModel({ ...model, id: id, sectionId: sectionId, sectionEncode: sectionEncode })
            inspectorRef.current = inspector
            // setInspector(() => {
            //     console.log('setting inspector callback')
            //     return inspector
            // }))
        } else {
            if (pageRenderer && pageRenderer.inspector) {
                inspectorRef.current = pageRenderer.inspector.bind(pageRenderer)    
                setInspectorModel({ ...currentPage.props, id: null })
            } else {
                inspectorRef.current = null
                setInspectorModel(null)
            }

            setSelectedId(null)
        }
    }
    
    React.useEffect( () => {
        console.log(highlightedSection)
        if (highlightedSection) {
            setShowSidebar(false)
        }
    }, [highlightedSection])

    let s = {
        selectedPageId: selectedPageId, 
        selectedId: selectedId, 
        setSelectedId: setSelectedId, 
        setSelected: setSelected,
        setSelectedPageId: (id) => {
            setSelected(null)
            setSelectedPageId(id)
            setInspectorModel(null)
            inspectorRef.current = null
        },
        setHighlightedSection: setHighlightedSection,
        setInspectorModel: setInspectorModel,

        inspector : inspectorRef.current,
        inspectorModel: inspectorModel,
        pageStyle: currentPage ? currentPage.style : null,
        pages: pages ?? [],
        yapuiJSON: yapuiJSON,
        multiplePages: props.multiplePages,
        styles: props.styles ?? styles,
        fonts: props.fonts ?? fonts,
        dragData: dragData,
        currentPage: currentPage,
        onDeletePage: onDeletePage,
        onAddPage: onAddPage,
        updateModel: updateModel,
        addSection: addSection,
        deleteSection: deleteSection,
        moveSection: moveSection, 

        pageRenderer: pageRenderer,
        
        currentPageRef: currentPageRef, 
        currentSelectedSection: currentSelectedSection,
        selectedSectionId: selectedSectionId,
        selectedViewId: selectedViewId,

        sidebarState: sidebarState,
        setSidebarState: setSidebarState,

        //setShowSidebar: setShowSidebar,
        showSidebar: props.showSidebar,
        
        showAddSection: showAddSection,
        setShowAddSection: setShowAddSection,
        
        assets: props.assets,
        components: props.components,
        componentTemplates: props.componentTemplates,
        templates: props.templates,

        canAddComponents: props.componentTemplates != null && props.componentTemplates.length > 0,
        canCrop: props.canCrop,
        canMultiUpload: props.canMultiUpload,

        setAssetLibraryTypes: setAssetLibraryTypes,
        assetLibraryTypes: assetLibraryTypes,
        canAddAssetType: props.canAddAssetType,

        showAssetLibrary: showAssetLibrary,
        setShowAssetLibrary: setShowAssetLibrary,
        onAssetSelected: onAssetSelected,

        showTemplateLibrary: showTemplateLibrary,
        setShowTemplateLibrary: setShowTemplateLibrary,
        onTemplateSelected: onTemplateSelected,

        onAssetImport: props.onAssetImport,
        onAssetDelete: props.onAssetDelete,
        onAssetChange: props.onAssetChange,
        
        isReadonly: props.isReadonly,
        renderMarkdown: props.renderMarkdown,
        
        validationEnabled: validationEnabled,
        setValidationEnabled: setValidationEnabled,

        showComponentLibrary: showComponentLibrary,
        setShowComponentLibrary, setShowComponentLibrary,

        setInsertSectionIndex: setInsertSectionIndex,
        insertSectionIndex: insertSectionIndex,

        setInsertSectionGroup: setInsertSectionGroup,
        insertSectionGroup: insertSectionGroup,

        showInsertSection: showInsertSection,
        setShowInsertSection: setShowInsertSection,
        
        canInsertComponent: props.canInsertComponent,

        validations: props.validations,

        contentChanged: contentChanged,
        setContentChanged: setContentChanged,

        onDone : props.onDone,
        validationMessages: props.validationMessages,

        refreshInspector: refreshInspector,

        showDebug: props.showDebug != null ? props.showDebug : null, 

        showAssetEditModal: showAssetEditModal,
        setShowAssetEditModal: setShowAssetEditModal
    }

    const value = s
    return <AppStateContext.Provider value={value} {...props}/>
}

export { ContentBuilderStateProvider, useAppState}