/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { createContext, useContext, useMemo } from "react";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";

import { useNavigate } from "react-router-dom";
import { useSessionStorage } from 'usehooks-ts'

const auth = getAuth();

const AuthContext = createContext();

export const FirebaseAuthProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const [userData, setUserData] = useSessionStorage("userData", null);
    const [currentUser, setCurrentUser] = useState(userData);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserData(user);
0           }
            setCurrentUser(user);
            setLoading(false);
        });

        return unsubscribe;
    }, []);

    // useEffect( () => {
    //     if (userData) {
    //         navigate("/designer");
    //     }
    // }, [userData])
    
    const login = (email, password) => {
        setUserData(null);
        return signInWithEmailAndPassword(auth, email, password);
    };

    const logout = () => {
        setUserData(null);        
        return signOut(auth);
    };

    const value = {
        currentUser,
        login,
        logout,
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export const useAuthLoader = () => {
    const [userData, setUserData] = useSessionStorage("userData", null);
    return userData
};