import YapContentBuilder from "./content-builder-components/ContentBuilder.js"
import { Info } from './page-components/ui/Info.js'
import { Gallery } from './page-components/ui/Gallery.js'
import { ImageGallery } from './page-components/ui/ImageGallery.js'
import { View, useViewState } from './page-components/ui/View.js'
import { Spacer } from './page-components/ui/Spacer.js'
import { Seperator } from './page-components/ui/Seperator.js'
import { ActionButton } from './page-components/ui/ActionButton.js'
import { DateText } from './page-components/ui/DateText.js'
import { Video, VideoPlayer } from './page-components/ui/Video.js'
import { ZStack } from './page-components/ui/ZStack.js'
import { Text } from './page-components/ui/Text.js'
import { Stack } from './page-components/ui/Stack.js'
import { HStack } from './page-components/ui/HStack.js'
import { VStack } from './page-components/ui/VStack.js'
import { Box } from './page-components/ui/Box.js'
import { Image } from './page-components/ui/Image.js'
//import { SegmentedControl } from "./page-components/ui/SegmentedControl.js"
import SegmentedControl from "./uikit-components/SegmentedControl.js"
import { Sidebar, SidebarAction, SidebarSection, SidebarCategoryItem, SidebarContent, SidebarItem, SidebarSectionHeader, SidebarToolbar , SidebarToolbarAction } from "./components/Sidebar.js"
import { Inspector, InspectorContent, InspectorImageActions, InspectorControlSegmented,  InspectorControlDate,  InspectorControlText, useInspectorState , InspectorStateProvider } from "./components/Inspector.js"
import { InspectorImageWell, InspectorImageWellCondensed } from './components/inspector-ui/InspectorImageWell.js'
import { InspectorVideoWell } from './components/inspector-ui/InspectorVideoWell.js'
import { InspectorModelWell } from './components/inspector-ui/InspectorModelWell.js'
import { InspectorHeader } from './components/inspector-ui/InspectorHeader.js'
import { InspectorProperty } from './components/inspector-ui/InspectorProperty.js'
import { InspectorControlButton } from "./components/inspector-ui/InspectorControlButton.js"
import { InspectorControlSelect } from "./components/inspector-ui/InspectorControlSelect.js"
import { InspectorControlSlider } from "./components/inspector-ui/InspectorControlSlider.js"
import { InspectorControlSwitch } from "./components/inspector-ui/InspectorControlSwitch.js"
import { InspectorListItems } from "./components/inspector-ui/InspectorListItems.js"
import { VideoInspectorControls } from "./components/inspectors/InspectorVideo.js"
import { Section } from "./page-components/ui/Section.js"
import { SectionAdd as YapSectionAdd } from "./page-components/SectionAdd.js"
import { AssetCatalogue as YapAssetCatalogue } from "./asset-components/AssetCatalogue.js"
import { AssetDetail as YapAssetDetail } from "./asset-components/AssetDetail.js"
import { Page as YapPage, PageContent as YapPageContent, useYapPageState } from "./uikit-components/Page.js"
import { Notification as YapNotification } from "./uikit-components/Notification.js"
import { PolymerMenu as YapContextMenu, PolymerMenuOption as YapContextMenuOption, PolymerMenuActionMore as YapContextMenuActionMore, PolymerMenuDivider as YapContextMenuDivider, PolymerMenuHeader as YapContextMenuHeader } from "./uikit-components/Menu.js"
import { TableSection as YapTableSection, TableSectionActionInfo as YapTableSectionActionInfo } from "./shared-components/TableSection.js"
import YapHeader from "./uikit-components/Header.js"
import YapHeaderButton from "./uikit-components/HeaderButton.js"
import YapAssetImage from "./asset-components/AssetImage.js"
import YapAssetType from "./asset-components/AssetType.js"
import YapScrollable from './uikit-components/Scrollable.js' 
import YapToolbar from "./uikit-components/Toolbar.js"
import YapProgressBar from "./uikit-components/ProgressBar.js"

import { ContentPreview as YapContentPreview, ContentPreviewStatic as YapContentPreviewStatic } from "./preview-components/ContentPreview.js"
import YapContentPreviewsModal from "./preview-components/ContentPreviewsModal.js"
import YapContentValidations from "./content-builder-components/ContentValidations.js"
import packageInfo from "../package.json"
import { useCanvas } from "./content-builder-components/ContentBuilderCanvasState.js"
import { ContentBuilderIconProviderContext as YapContentBuilderIconProviderContext, useIcons } from "./content-builder-components/ContentBuilderIconProvider.js"
import { ContentBuilderModal as YapContentBuilderModal } from './content-builder-components/ContentBuilderModals.js'
import { useAppState } from './content-builder-components/ContentBuilderStateProvider.js'
import { YapUIGenerator, YapUI } from "./yap-ui/YapUIGenerator.js"
import { Component, Modifier } from "./yap-ui/YapUIReact.js"
import { DocumentYapUI } from "./yap-ui/DocumentYapUI.js"
import { JSONFormatter } from "./shared-components/JSONFormatter.js"
import YapAssetLibraryAlbums from "./asset-components/AssetLibraryAlbums.js"
import YapAssetLibraryAssets  from "./asset-components/AssetLibraryAssets.js"
import YapAssetFileUploader from "./asset-components/AssetFileUploader.js"

import { AssetLibraryBrowser as YapAssetLibraryBrowser, AssetLibraryAssetDetail as YapAssetLibraryAssetDetail }  from "./asset-components/AssetLibraryBrowser.js"

export { 
    YapContentBuilder, 
    YapContentPreview, 
    YapContentPreviewStatic, 
    YapContentValidations, 
    YapContentPreviewsModal,
    YapContentBuilderModal,
    YapContentBuilderIconProviderContext,
    YapAssetCatalogue, 
    YapAssetType, 
    YapAssetDetail, 
    YapAssetImage, 
    YapPage, YapPageContent,
    YapContextMenu,
    YapContextMenuOption,
    YapContextMenuActionMore,
    YapContextMenuDivider,
    YapContextMenuHeader,
    YapNotification,
    YapHeader, 
    YapHeaderButton,
    YapToolbar,
    YapProgressBar,
    YapScrollable,
    YapSectionAdd,

    YapTableSection,
    YapTableSectionActionInfo,

    YapAssetLibraryBrowser,
    YapAssetLibraryAssets,
    YapAssetLibraryAlbums,
    YapAssetFileUploader,
    YapAssetLibraryAssetDetail,
    Sidebar, SidebarAction, SidebarSection, SidebarContent, SidebarCategoryItem, SidebarItem, SidebarSectionHeader, SidebarToolbar, SidebarToolbarAction,
    SegmentedControl,
    YapUIGenerator,
    DocumentYapUI,
    Component,
    Modifier,
    YapUI,
    useYapPageState, 
    useAppState,
    Inspector, 
    InspectorProperty, 
    InspectorHeader,
    InspectorControlButton,
    InspectorControlSegmented, 
    InspectorContent,
    InspectorControlDate, 
    InspectorControlText,
    InspectorControlSelect,
    InspectorControlSlider,
    InspectorControlSwitch,
    InspectorImageWell, InspectorImageWellCondensed, InspectorModelWell,
    InspectorVideoWell,
    InspectorImageActions,
    InspectorListItems,
    VideoInspectorControls,

    InspectorStateProvider,
    useInspectorState,

    useViewState,
    useIcons,
    useCanvas,
    JSONFormatter,
    View, Section, Video, VideoPlayer, Image, ImageGallery, Text, Stack, HStack, VStack, Seperator, Spacer, Box, ZStack, DateText, Gallery, ActionButton, Info 
} 

function yapDebug() {
    console.log(packageInfo.name)
    console.log('--------')
    console.log('Version ' + packageInfo.version)
}

window.yapDebug = yapDebug