import React, { useState, useEffect } from 'react';
import { useStyle, ClearStyle, StyleProvider } from '../Style.tsx';
import { useActions } from '../Actions.tsx';

export function ScrollView({ children }) {
    const style = { ...useStyle() }
    style['overflow'] = 'scroll';
    //style['display'] = 'flex'

    const containerInfo = {
        '_container': 'scrollview'
    }
    return <div className="scrollview" style={style}><ClearStyle><StyleProvider style={containerInfo as any}>{children}</StyleProvider> </ClearStyle></div>
}