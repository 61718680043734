/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useAuth } from './Auth.js' 

import { createContext, useContext, useMemo } from "react";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";

import { useNavigate } from "react-router-dom";
import { useSessionStorage } from 'usehooks-ts'

import { YapPage } from '@yapstudios/yap-content-builder'

import VerificationInput from "react-verification-input";

// Data model methods
import { validateCode, loadConfig } from './AppData.js'

// Styling
import styled from 'styled-components'


export function Login() {
    const { login, logout, currentUser } = useAuth();
    const [email, setEmail] = useState(''); 
    const [password, setPassword] = useState('');   
    const navigate = useNavigate()

    const onSubmit = (e) => {
        e.preventDefault(); 
        login(email, password)
        return false
    }

    useEffect(() => {   
        if (currentUser ) {
            navigate('/designer')  
        }
    }, [currentUser])

    return (
        <YapPage fullscreen={true} >
            <div className='login-content'>
                <LoginContainer onSubmit={onSubmit}>
                        <input type="textfield" className="polymer-large-textfield" placeholder="Email" value={email} onChange={(event) => {
                            setEmail(event.target.value)
                        }}  onSubmit={onSubmit}/>
                        <input type="password" className="polymer-large-textfield" placeholder="Password" value={password} onChange={(event) => {
                            setPassword(event.target.value)
                        }} onSubmit={onSubmit}/>
                        <input type="submit" value="Login" className="polymer-button"/>
                </LoginContainer>                
            </div>
        </YapPage>)
}

const LoginContainer = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 350px;
    padding: 40px;
`

