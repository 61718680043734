import * as React from "react";
import * as ReactDOM from "react-dom/client";

import { ContentIndexAuthLoader, indexLoader } from "./ContentIndex";
import { ContentBuilderAuthLoader, contentLoader } from "./ContentBuilder";
import { ContentAccess } from "./ContentAccess";

import { AuthProvider } from "./useAuth.js"
import { ContentEditorAuthLoader, contentEditorLoader, YapDesignerProjects, loaderProjects, ProjectsRoute, ProjectRoute } from "./YapDesigner/YapDesigner.js"
import { YapMirrorLoader, yapMirrorLoader } from "./YapPreview/YapPreview.js"
import {
    createBrowserRouter,
    RouterProvider,
    defer
} from "react-router-dom";


import { Login } from './YapDesigner/Login.js';
import { FirebaseAuthProvider } from "./YapDesigner/Auth.js";
const router = createBrowserRouter([
    {
        path: "/",
        element: <ContentIndexAuthLoader/>,
        loader: () => defer({ userPromise: indexLoader })
    },
    {
        path: "/login",
        element: <AuthProvider><ContentAccess /></AuthProvider>
    },    
    {
        path: "/logind",
        element: <FirebaseAuthProvider><Login /></FirebaseAuthProvider>
    },        
    {
        path: "/designer",
        element: <ProjectsRoute/>        
    },       
    {
        path: "/designer/:project/*",
        element: <ProjectRoute/>
    },           
    {
        path: "/:documentId",
        element: <ContentBuilderAuthLoader/>,
        loader: () => defer({ userPromise: contentLoader })        
    },    
    // {
    //     path: "/mirror/*",
    //     element: <YapPreviewLoader/>,
    //     loader: () => defer({ userPromise: yapMirrorLoader })                
    // },    

]);

const App = () => {
    return (
        <div>
            <RouterProvider router={router} />
        </div>
    )
}

export default App