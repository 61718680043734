import React, { useState, useEffect, useLayoutEffect, Suspense, useRef } from 'react'

// YapContentBuilder components
import { 
    YapHeaderButton,
    YapContextMenu,
  } from '@yapstudios/yap-content-builder'


import { Issue, IssueSeverity } from './Issue.ts'  

// Styling
import styled from 'styled-components'

interface IssuesMenuProps {
    issues: Issue[];    
}

export const IssuesMenu = ({ issues } : IssuesMenuProps) => {    

    let error = (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.53809 18.6191C8.31348 18.6191 7.15918 18.3848 6.0752 17.916C4.99707 17.4473 4.04492 16.7998 3.21875 15.9736C2.39258 15.1475 1.74512 14.1953 1.27637 13.1172C0.807617 12.0332 0.573242 10.8789 0.573242 9.6543C0.573242 8.42969 0.807617 7.27832 1.27637 6.2002C1.74512 5.11621 2.38965 4.16113 3.20996 3.33496C4.03613 2.50879 4.98828 1.86133 6.06641 1.39258C7.15039 0.923828 8.30469 0.689453 9.5293 0.689453C10.7539 0.689453 11.9082 0.923828 12.9922 1.39258C14.0762 1.86133 15.0312 2.50879 15.8574 3.33496C16.6836 4.16113 17.3311 5.11621 17.7998 6.2002C18.2686 7.27832 18.5029 8.42969 18.5029 9.6543C18.5029 10.8789 18.2686 12.0332 17.7998 13.1172C17.3311 14.1953 16.6836 15.1475 15.8574 15.9736C15.0312 16.7998 14.0762 17.4473 12.9922 17.916C11.9141 18.3848 10.7627 18.6191 9.53809 18.6191ZM9.53809 11.2891C9.99512 11.2891 10.2266 11.0488 10.2324 10.5684L10.3643 5.78711C10.3701 5.55859 10.291 5.36816 10.127 5.21582C9.96875 5.05762 9.76953 4.97852 9.5293 4.97852C9.2832 4.97852 9.08105 5.05469 8.92285 5.20703C8.77051 5.35937 8.69727 5.5498 8.70312 5.77832L8.81738 10.5684C8.8291 11.0488 9.06934 11.2891 9.53809 11.2891ZM9.53809 14.2334C9.80176 14.2334 10.0303 14.1426 10.2236 13.9609C10.4229 13.7793 10.5225 13.5566 10.5225 13.293C10.5225 13.0293 10.4229 12.8066 10.2236 12.625C10.0303 12.4375 9.80176 12.3438 9.53809 12.3438C9.26855 12.3438 9.03711 12.4375 8.84375 12.625C8.65039 12.8125 8.55371 13.0352 8.55371 13.293C8.55371 13.5566 8.65039 13.7793 8.84375 13.9609C9.04297 14.1426 9.27441 14.2334 9.53809 14.2334Z" fill="#E92A2A"/>
        </svg>
    )
    
    let warning = (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.72656 15.7422C2.28385 15.7422 1.90365 15.6484 1.58594 15.4609C1.26823 15.2734 1.02344 15.0182 0.851562 14.6953C0.679688 14.3776 0.59375 14.0208 0.59375 13.625C0.59375 13.2448 0.695312 12.8802 0.898438 12.5312L6.96094 1.96875C7.15885 1.61458 7.42188 1.34896 7.75 1.17188C8.07812 0.989583 8.42188 0.898438 8.78125 0.898438C9.14062 0.898438 9.48177 0.989583 9.80469 1.17188C10.1328 1.34896 10.3984 1.61458 10.6016 1.96875L16.6641 12.5312C16.763 12.7031 16.8385 12.8828 16.8906 13.0703C16.9427 13.2578 16.9688 13.4427 16.9688 13.625C16.9688 14.0208 16.8828 14.3776 16.7109 14.6953C16.5391 15.0182 16.2943 15.2734 15.9766 15.4609C15.6589 15.6484 15.2786 15.7422 14.8359 15.7422H2.72656ZM8.78906 10.5156C9.19531 10.5156 9.40104 10.3021 9.40625 9.875L9.52344 5.625C9.52865 5.42188 9.45833 5.2526 9.3125 5.11719C9.17188 4.97656 8.99479 4.90625 8.78125 4.90625C8.5625 4.90625 8.38281 4.97396 8.24219 5.10938C8.10677 5.24479 8.04167 5.41406 8.04688 5.61719L8.14844 9.875C8.15885 10.3021 8.3724 10.5156 8.78906 10.5156ZM8.78906 13.1328C9.02344 13.1328 9.22656 13.0521 9.39844 12.8906C9.57552 12.7292 9.66406 12.5312 9.66406 12.2969C9.66406 12.0625 9.57552 11.8646 9.39844 11.7031C9.22656 11.5365 9.02344 11.4531 8.78906 11.4531C8.54948 11.4531 8.34375 11.5365 8.17188 11.7031C8 11.8698 7.91406 12.0677 7.91406 12.2969C7.91406 12.5312 8 12.7292 8.17188 12.8906C8.34896 13.0521 8.55469 13.1328 8.78906 13.1328Z" fill="#E7AE04"/>
        </svg>
    )

    let hasErrors = issues.filter((issue) => issue.severity === IssueSeverity.Error).length > 0 
    let hasWarnings = issues.filter((issue) => issue.severity === IssueSeverity.Warning).length > 0 

    if (!(hasErrors || hasWarnings)) {
        return null
    }

    const issueItems = issues.map((issue, index) => {
        return <IssueItem className={index == 0 ? "first" : ""}>
                    <div className="issue-icon">    
                        {issue.severity === IssueSeverity.Error ? error : warning}  
                    </div>
                    {issue.message}
                </IssueItem>
    })
    
    var content = (
        <YapContextMenu width={300} align="left" action={<YapHeaderButton>{hasErrors ? error : warning}</YapHeaderButton>}>
            <ContentContainer>
                {issueItems}
            </ContentContainer>
        </YapContextMenu>
    )

    return content
}

const IssueItem = styled.div`   
    border-top: 1px solid #e0e0e0;
    &.first {
        border-top: 0px;
    }

    padding: 10px;
    font-size: 14px;
    color: #333;
    display: flex;
    flex-direction: row;
    gap: 10px;

    .issue-icon {   
        min-width: 20px;
    }
`

const ContentContainer = styled.div`    
    padding: 20px;
    display: flex;
    flex-direction: column;
`