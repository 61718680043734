import React, { useState, useEffect } from 'react';
import { useStyle, useForegroundStyle } from '../Style.tsx';
import styled from 'styled-components'
import { useActions } from '../Actions.tsx';
import { ClearStyle } from '../Style.tsx';

interface TextProps {
    text?: string;
    value?: string;
    rawValue?: string;
    children?: React.ReactNode[];
}

export default function Text({ text , value, rawValue, children } : TextProps) { 
    const actions = {...useActions()}
    const style = useStyle()
    const foregroundStyle = useForegroundStyle()
    
    const elementStyle = {
        margin: '0px'
    }

    const content = rawValue ?? value ?? text ?? children;

    return (
        <p className="text" {...actions} style={{...elementStyle, ...style, ...foregroundStyle}}><ClearStyle>{content}</ClearStyle></p>
    );
}

// const _P = styled('p').withConfig({ shouldForwardProp: () => { return true } })`
//     margin: 0px;
// `