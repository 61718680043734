import React, { createContext, useContext, ReactNode } from 'react';

// Define a type for the style context
export interface IDContextType {
    id: string | null;
}

// Create the StyleContext with an empty default value
export const IDContext = createContext<IDContextType>({ id: null });

export function useID() : string | null {
    return useContext(IDContext).id
}