/* eslint-disable */
import React, { useState, useEffect, useLayoutEffect, Suspense } from 'react'

// Styling
import styled from 'styled-components'

// Import YapContentBuilder components
import { 
    YapSectionAdd
} from '@yapstudios/yap-content-builder'

import { Section, Stack, Text, Image  } from '@yapstudios/yap-content-builder';

import AST from '../YapUI/YapUIAST.js'
import JS from '../YapUI/YapUIJS.js'    

// YapUI Rendering
import { Renderer } from '../YapUI/Renderer/Renderer.js'

import { ComposerInspector } from './ComposerInspector.js';

export class YapUIPageRenderer {

    constructor({ runtime, name, viewCallback, assetCallback,  defaultsCallback, inspectorContent, composerFrame }) {
        // Current Default Frame name
        this.frameName = composerFrame

        // Callbacks
        this.runtime = runtime

        this.name = name

        this.viewCallback = viewCallback
        this.assetCallback = assetCallback
        this.defaultsCallback = defaultsCallback

        this.inspectorContent = inspectorContent
    }

    yapUI(props, children, page) {
        return { content: JS.Directive(this.frameName, props, children.map(( child ) => child.content )) }
    }

    rootYapUI(props, children) {
        let body = JS.EntryPoint('body', {} , children.map((child) => child.content))

        let thumbnail = JS.EntryPoint('thumbnail', {}, [
            JS.Directive(this.frameName + "_thumbnail", {}, [ 
                JS.Directive(this.name.replace(/\s/, ''), {}, [])
            ], [])
        ])

        let componentInfo = JS.EntryPoint('component', {}, [
            JS.JSON({ name: 'Some Thing', version: '1.0.0', description: 'Some thing' })    
        ])

        return [componentInfo, body, thumbnail ].join('\n\n')

        // return YapUIJS.Body(props, children)
        return AST.Directive('Composer', props, children)
    }

    render(page) {
        return <ComposerPage runtime={this.runtime} viewCallback={this.viewCallback} assetCallback={this.assetCallback} defaultsCallback={this.defaultsCallback} frameName={this.frameName} pageProps={page.props}/>
    }

    inspector(model, updateModel) {
        /** Decode inspector JSON */
        var inspector = {}
        try {
            if (this.inspectorContent) {
                inspector = JSON.parse(this.inspectorContent)  
            } else {
                console.log('no inspector')
            }
        } catch(e) { 
            //console.error('Error decoding inspector', e, this.inspector)    
        }

        return <ComposerInspector name={"Page"} inspector={inspector} model={model} updateModel={updateModel}/>
    }
}

function ComposerPage({ viewCallback, assetCallback, defaultsCallback, frameName, componentSections, pageProps, runtime }) {
    const environment = {
        'environment.preview' : 'design',
        'preview' : 'design'
    }

    const callback = (name, props) => {
        if (name == 'ComposerComponent') {
            return componentSections.find((section) => section.props.id == props.id)
        } else if (name == 'ComposerAdd') {
            return <AddContainer className="addContainer"><YapSectionAdd group={props.group}/></AddContainer>
        // } else if (name == 'ComposerChildren' || name == 'Children')  {
        //     return 'children'
        //     return <ComposerGroup name={"main"} components={componentSections}/>
        } else {
            let view = viewCallback ? viewCallback(name, {}, callback) : null
            return view
        }
    }

    const sectionItems = componentSections.map((section, index) => {    
        return AST.Directive('ComposerComponent', { id: section.props.id, sectionId: section.props.id }, [])   
    })

    let frameNameWithoutPath = frameName.split('/').pop() 

    return (
        <PageRendererContainer>
            <Renderer componentName={frameNameWithoutPath}  
                      componentChildren={sectionItems}    
                      props={pageProps}
                      allowDefaultFrame={false}
                      runtime={runtime}
                      updateEveryRender={true} 
                      viewCallback={callback}
                      assetCallback={assetCallback} 
                      defaultsCallback={defaultsCallback} 
                      preferredType={'body'}
                      environment={{...pageProps, ...environment}}/>
        </PageRendererContainer>
    )
}

// function ComposerGroup({ name, components }) {
//     if (components && components.length > 0) {
//         return components
//     } else {
//         return <AddContainer className="addContainer"><YapSectionAdd group={name}/></AddContainer>
//     }
// }

const PageRendererContainer = styled.div`
    & > :first-child {
        overflow: unset;
    }   

    & .section {
        display: contents;
        //  width: -webkit-fill-available;
    }

    & .rendererContainer :first-child {  
        overflow: unset;
        height: auto;
    }

`

const AddContainer = styled.div`  
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    & .section-add {
        height: 100%;
        background: #fafafa;
        border-radius: 10px;    
    }
`

