import React, { useState, useRef } from 'react'

// Styling
import styled from 'styled-components'

import { fileUploader } from '../AppData.js'

import { 
        YapAssetLibraryAssets,
        YapAssetFileUploader,
        YapAssetLibraryAssetDetail,
        YapAssetType,
        YapToolbar,
        YapScrollable,
        YapProgressBar,
        YapHeaderButton,
        YapAssetLibraryAlbums,
        YapTableSection,
        YapContextMenuActionMore,
        YapAssetImage,
        useIcons
 } from '@yapstudios/yap-content-builder'

 import { v4 as uuidv4 } from 'uuid';

 import { YapContextMenu, YapContextMenuOption } from '@yapstudios/yap-content-builder'

 import { RgbaColorPicker }  from "react-colorful";

 import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";

 /**
  * View Model
  * @param {*} param0 
  * @returns 
  */
export function useAssets({ editor, assets, setAssets, selectedPath, setSelectedPath }) {    

    // Get child path item for current path.
    var selectedChildPath = null
    if (selectedPath != null && String(selectedPath).indexOf('.assets/') > -1) {
        let items = String(selectedPath).split('.assets/')
        selectedChildPath = items[1]  
    }

    const selectedAssetSet = assets.filter(item => item.name != null).find((item) => item.name == selectedChildPath)

    const [selectedAsset, setSelectedAsset] = useState(null);
    const [showUploadAsset, setShowUploadAsset] = useState(false);
    const [uploadingAsset, setUploadingAsset] = useState(false); 
    const [uploadProgress, setAssetUploadProgress] = useState(0)
    const [uploadProgressInfo, setAssetUploadProgressInfo] = useState({ index: 0, total: 0 })
    const [uploadingError, setUploadingError] = useState(null)

    const uploadCompleteCallback = useRef(null)
    const uploadCancelCallback = useRef(null)
    
    const uploadingAssetRef = React.useRef(null)
    uploadingAssetRef.current = uploadingAsset

    const assetsRef = React.useRef(null)
    assetsRef.current = assets
    
    const selectedAssetSetRef = React.useRef(null)
    selectedAssetSetRef.current = selectedAssetSet

    const onComplete = useRef(null)

    const libraryTypes = [YapAssetType.Image, YapAssetType.Video]
    
    const selectAssetSetId = (id) => {
        const assetSet = assets.find((item) => item.id == id)
        let newPath = selectedPath + '/' + assetSet.name
        setSelectedPath(newPath)
    }

    React.useEffect(() => { 
        setSelectedAsset(null);
    }, [selectedPath])

    const cancelUpload = () => {
        if (uploadCancelCallback.current) {
            uploadCancelCallback.current()
        }
    }

    const addNewImage = () => {
        let assetContainer = {
            id: uuidv4(),
            name: 'New Image',
            type: YapAssetType.Image.id,
            created: new Date(),
            assets: []
        }

        assetsRef.current = [...assetsRef.current, assetContainer]
        setAssets(assetsRef.current)
    }

    const addNewColor = () => {
        let assetContainer = {
            id: uuidv4(),
            name: 'New Color',
            type: YapAssetType.Color.id,
            created: new Date(),
            assets: [ { r: 0, g: 0, b: 0, a: 1.0, type: YapAssetType.Color.id, name: 'Any', id: uuidv4() } ]
        }
        assetsRef.current = [...assetsRef.current, assetContainer]
        setAssets(assetsRef.current)

    }

    const updateAssetSet = (assetItem) => {
        let index = assetsRef.current.findIndex((item) => item.id == assetItem.id)

        let existingAssetSet = assetsRef.current[index]
        let newAssetSet = assetItem

        let existingPath = selectedPath
        let newPath = existingPath.replace('/' + existingAssetSet.name, '/' + newAssetSet.name) 

        assetsRef.current[index] = newAssetSet
        setAssets(assetsRef.current)
        if (selectedPath != newPath) {
            setSelectedPath(newPath)
        }
    }

    const onAssetImport = (file, asset, album, progressCallback, completeCallback, errorCallback) => {
        
        console.log(asset)

        const uploadTask = fileUploader({
            file: file,
            asset: asset,
            progressCallback: progressCallback,
            completeCallback: (asset) => {
                let newItem = { ...selectedAssetSetRef.current  }

                var finalAsset = { url: asset.src,
                    id: uuidv4(),
                    dimensions: asset.dimensions,
                    crop: asset.crop,
                    alt: asset.alt,
                    type: YapAssetType.Image.id,
                    name: asset.name ?? asset.title }
                
                // delete undefined properties
                Object.keys(finalAsset).forEach(key => finalAsset[key] === undefined && delete finalAsset[key])

                console.log(finalAsset)

                newItem.assets = newItem.assets != null ? [...newItem.assets, finalAsset] : [finalAsset]
                console.log(newItem)
                updateAssetSet(newItem)
                completeCallback(finalAsset)
            },
            errorCallback: errorCallback,
        })

        // Return value to onAssetImport is a function that will be called if the upload is cancelled by the user
        return () => {
            uploadTask.cancel()
        }
    }

    const deleteAssetSet = (assetSet) => {  
        setAssets(assets.filter((a) => a.id != assetSet.id))
        setSelectedPath(selectedPath.replace('/' + assetSet.name, ''))  
    }

    const updateAsset = (assetSet, asset) => {  
        var newSet = { ...assetSet }    
        newSet.assets = assetSet.assets.map((a) => {
            if (a.id == asset.id) {
                return asset
            } else {
                return a
            }
        })
        updateAssetSet(newSet)
    }

    const deleteAsset = (assetSet, assetItem) => {    
        var newSet = {...assetSet}
        newSet.assets = newSet.assets.filter((a) => a.id != assetItem.id)   
        updateAssetSet(newSet)
    }


    const progressCallback = (progress, progressInfo, uploading, error) => {
        if (uploadingAssetRef.current == false) {
            console.warn(`progressCallback called while no asset uploading. progress [${progress}]`);
            return;
        }

        let total = progressInfo.total
        let index = progressInfo.index

        let fileProgress = index / total
        let totalProgress = fileProgress + (progress / total)

        if (error) {
            setUploadingError(error)
            setUploadingAsset(false)
            setAssetUploadProgress(0)
        } else {
            if (uploading != null && uploading == false && index == total - 1) {
                setAssetUploadProgress(1.0)
                setUploadingAsset(false)       
                console.log('- upload complete')             
            } else {
                setAssetUploadProgress(totalProgress)
                setUploadingAsset(true)        
                console.log(`- upload progress [${totalProgress}]`);
            }
        }
    }

    return { assets, setAssets, 
            selectAssetSetId,
            selectedAsset, setSelectedAsset, 
            selectedAssetSet,  //selectedAssetSetId, setselectedAssetSetId,
            showUploadAsset, setShowUploadAsset,
            uploadingAsset, setUploadingAsset ,
            uploadProgress, setAssetUploadProgress,
            uploadProgressInfo, setAssetUploadProgressInfo,
            uploadingError, setUploadingError,
            uploadCompleteCallback, uploadCancelCallback,
            progressCallback,
            cancelUpload,
            onComplete,
            onAssetImport,
            addNewImage, addNewColor,
            updateAssetSet,
            updateAsset,
            deleteAsset, deleteAssetSet,
            libraryTypes
     }
}


export function Assets({ assets }) {
    
    var uploadStatusToolbar = null
    if (assets.uploadingAsset) {
        const uploadingStatus = 
            (<AssetsUploadInfo>
                    <p>Uploading {assets.uploadProgressInfo.index + 1} of {assets.uploadProgressInfo.total}</p>
                    <YapProgressBar progress={assets.uploadProgress} />
            </AssetsUploadInfo>
            );

        const uploadCanceAction = [
            <YapHeaderButton key="cancel" onClick={() => { assets.cancelUpload() }}>{"Cancel"}</YapHeaderButton>
        ]

        uploadStatusToolbar = <YapToolbar actionsLeft={uploadingStatus} actionsRight={uploadCanceAction}></YapToolbar>
    }


    var content = null
    if (assets.selectedAssetSet) {
        content = <AssetSetContent assets={assets} assetItem={assets.selectedAssetSet} />
    } else {
        content = (
            <AssetSets assets={assets.assets ?? []} onSelect={(asset) => {
                assets.selectAssetSetId(asset.id)
            }} />   
        )
    }
    
    return (
        <AssetsContainer>
            <YapScrollable>
                <AssetsContainerContent>
                   {content}                    
                </AssetsContainerContent>
            </YapScrollable> 
            {uploadStatusToolbar}
        </AssetsContainer>       
    )
}

/**
 * Library
 */
export function AssetSets({ assets, onSelect }) {
    var contentMode = contentMode ?? "fit"
    const validAssets = assets

    return (
        <div className={`polymer-grid polymer-grid-albums-columns  polymer-grid-assets-content-mode-${contentMode}`} role="grid">
            {validAssets.map((item) => {
                let firstAsset = item.assets && item.assets.length ? item.assets[0] : null

                const title = item.name ?? item.title ?? item.src

                const previewItem = firstAsset ?
                        <AssetSetAssetItem
                            contentMode={contentMode}
                            asset={firstAsset}
                            key={item.url}
                            title={title}
                            id={item.url}
                            ></AssetSetAssetItem> : <AssetPlaceholder/>

                return (
                    <AssetSetCell onClick={() => {
                        if (onSelect) {
                            onSelect(item)
                        }
                    }}>
                        {previewItem}
                        {title && <AssetTitle className="title">{title}</AssetTitle>}
                    </AssetSetCell>
                )
            })}
        </div>
    )
}


/**
 * Set Detail
 * @param {} param0 
 * @returns 
 */
export function AssetSetContent({ assets, assetItem }) {
    return <AssetSetAssets assets={assetItem.assets}  selectedAsset={assets.selectedAsset} onAssetSelected={(asset) => {
            asset != assets.selectedAsset ? assets.setSelectedAsset(asset) : assets.setSelectedAsset(null)
    }} />
}


/**
 * Detail
 * @param {*} param0 
 * @returns 
 */
export function AssetsDetail({ assets }) {
    const { selectedAsset, selectedAssetSet, onAssetEdit, onAssetDeleteAction } = assets

    var content = null
    var detailContent = null
    var title = "Asset"
    if (selectedAsset) {
        if (selectedAsset.type == YapAssetType.Image.id) {
            title = "Image"
            detailContent = <AssetsDetailAssetImage assets={assets}  asset={selectedAsset} deleteAsset={(asset) => {
                assets.deleteAsset(selectedAssetSet, asset)    
            }}/>
        } else if (selectedAsset.type == YapAssetType.Color.id) {
            title = "Color"
            detailContent = <AssetsDetailAssetColor assets={assets}  asset={selectedAsset} updateAsset={(asset) => {
                assets.updateAsset(selectedAssetSet, asset)  
            }}/>
        }
    }

    if (selectedAssetSet) {
        content = <AssetsDetailAssetSet  assets={assets} asset={selectedAssetSet}/>
    }
    //}

    return (
        <YapScrollable>        
            <AssetsDetailContainer>
                <div className="asset-detail-content"> 
                {content}   

                {detailContent && <YapTableSection tableHeader={true} title={title} actions={null}></YapTableSection>}
                {detailContent}
                </div>
            </AssetsDetailContainer>
        </YapScrollable>        
    )
}

export function AssetsDetailAssetSet({ assets, asset }) { 
    const [name, setName] = useState(asset.name)    

    let icons = useIcons()
    let canDelete = true
    let onDeleteMenuOption = () => {
        assets.deleteAssetSet(asset)    
    }

    var menu = (
        <YapContextMenu action={<YapContextMenuActionMore />}>
            {canDelete && <YapContextMenuOption title="Delete" onClick={onDeleteMenuOption} icon={icons.get('menu.trash', 'color-destructive')} destructive={true} />}
        </YapContextMenu>
    )

    const updateName = (e) => {
        assets.updateAssetSet({...asset, name: name})

        e.preventDefault()
        return false
    }

    let title = "Asset Set"
    if (asset.type == YapAssetType.Color.id) { 
        title = "Color Set"
    } else if (asset.type == YapAssetType.Image.id) {
        title = "Image Set"
    }

    return (
        <div className="asset-detail-content">
            <YapTableSection tableHeader={true} title={title} actions={menu}>
                <form  onSubmit={updateName} >
                <input type="text"  className={"polymer-large-textfield"} value={name} onBlur={updateName} onChange={(e) => {
                    setName(e.target.value)
                }}/>
                </form>
            </YapTableSection>
        </div>
    )
}

export function AssetsDetailAssetImage({  asset, deleteAsset, editAsset }) {
    return (
        <YapAssetLibraryAssetDetail key={asset.assetVersion} onDelete={deleteAsset} onEdit={editAsset} asset={{...asset, src: asset.url, canDelete: true, canEdit: true, title: asset.name }} />
    )
}


export function AssetsDetailAssetColor({ asset , updateAsset }) {
    var c = (asset ? asset.hex : null) ?? "#000000"
    const [color, setColor] = useColor(c);

    
    return (
        <YapTableSection tableHeader={true} title={asset.name} actions={null}>
            <ColorPicker color={color} onChange={(color) => {
                let newAsset = {...asset}  
                newAsset.hex = color.hex
                newAsset.r = color.rgb.r
                newAsset.g = color.rgb.g
                newAsset.b = color.rgb.b
                newAsset.a = color.rgb.a
                updateAsset(newAsset)
                setColor(color)
            }} />;
            {/* <RgbaColorPicker color={asset} onChange={(color) => {
                let newAsset = {...asset}   
                newAsset.r = color.r
                newAsset.g = color.g
                newAsset.b = color.b
                newAsset.a = color.a
                updateAsset(newAsset)
            }} /> */}
        </YapTableSection>
    )
}

export function AssetsActions({ assets }) {
    if (assets.selectedAssetSet)  {
        return (
            [
                <YapHeaderButton key="add"><AssetUploaderButton assets={assets}/></YapHeaderButton>
            ]
        )
    } else {

        return (
            [
            <YapContextMenu action={<YapHeaderButton key="add">{"Add"}</YapHeaderButton>} verticalAlign="bottom" align="right">
                <YapContextMenuOption title="Image"  onClick={() => {
                    assets.addNewImage()
                }}/>
                <YapContextMenuOption title="Color"  onClick={() => {
                    assets.addNewColor()
                }}/>
            </YapContextMenu>
            ]
        )
    }
}



function AssetSetAssets({ assets, cols = 3, selectedAsset, onAssetSelected, contentMode }) {

    var contentMode = contentMode ?? "fit"
    const validAssets = assets



    return (
        <div className={`polymer-grid polymer-grid-assets-columns  polymer-grid-assets-content-mode-${contentMode}`} role="grid">
            {validAssets.map((item) => {     
                const title = item.name ?? item.title          
                return (
                    <AssetSetCell>
                    <AssetSetAssetItem 
                        contentMode={contentMode}
                        asset={item} 
                        key={item.src} 
                        selected={selectedAsset && selectedAsset.id == item.id} 
                        isIcon={false} 
                        title={item.name ?? item.title ?? item.src} 
                        id={item.src} 
                        onClick={() => {
                            if (onAssetSelected) {
                                onAssetSelected(item)
                            }
                        }}></AssetSetAssetItem>
                        {title && <AssetTitle className="title">{title}</AssetTitle>}
                    </AssetSetCell>
                )
            })}
        </div>
    )

}

function AssetSetAssetItem(props) {
    const contentMode = props.contentMode
    const className = `asset-item content-mode-${contentMode} ${props.selected ? " selected" : ""}`

    const handlePress = (e) => {
        let keyCode = e.keyCode ? e.keyCode : e.which;

        // Enter key
        if (keyCode === 13 || keyCode == 32) {
            props.onClick()
            e.preventDefault()
        }
    }

    var asset = props.asset
    var url = asset.url

    const fileExtension = url ? url.fileExtension() : null
    const isColor =  (asset.r != null && asset.g != null && asset.b != null)
    const isVideo =  YapAssetType.Video.fileTypes.includes(fileExtension)
    const isImage =  YapAssetType.Image.fileTypes.includes(fileExtension)

    var content = null
    if (isColor) {
        let color = `rgba(${asset.r}, ${asset.g}, ${asset.b}, ${asset.a})`
        content = <div style={{"backgroundColor" : color, width: '-webkit-fill-available', height: '-webkit-fill-available'}}></div>
    } else if (isVideo || isImage) {    
        content =   <YapAssetImage 
                        contentMode={contentMode} 
                        background={false} 
                        asset={{...props.asset, src: props.asset.url}} 
                        width={"100%"} 
                        height={"100%"}/>
    } else {
        content = asset.coverImage ? <YapAssetImage asset={asset.coverImage} width={"100%"} height={"100%"}/> : props.title   
    }

    return (
        <div role="button" tabIndex="0" className={className} onClick={props.onClick} onFocus={props.onFocus} onBlur={props.onBlur} onKeyUp={handlePress}>
            {content}
        </div>
    )
}



export function AssetUploaderButton({ assets }) {
    return (
    <YapAssetFileUploader 
        canMultiUpload={true}
        types={assets.libraryTypes}
        withDimensions={true}
        onWillBeginUploading={() => {
            assets.setUploadingAsset(true)
        }}
        onError={() => {
            assets.uploadCancelCallback.current = null
            assets.setUploadingAsset(false)
        }}
        onUploadFile={(file, dimensions, isVideo, progressInfo, onComplete) => {
            var asset = { id: uuidv4(), title: removeFileExtension(file.name), created: new Date(), type: file.type, isVideo: isVideo, size: file.size, dimensions: dimensions }

            assets.setAssetUploadProgress(0)  
            assets.setAssetUploadProgressInfo(progressInfo)
            assets.setUploadingAsset(true)
            assets.setSelectedAsset(asset) 
            
            assets.uploadCompleteCallback.current = onComplete

            const currentUploadCancelCallback = assets.onAssetImport(file, asset, { }, 
            (progress, uploading, error) => {
                if (assets.progressCallback) {
                    assets.progressCallback(progress, progressInfo, uploading, error)
                }
            }, (asset) => {

                if (progressInfo.index == progressInfo.total - 1) {
                    assets.setUploadingAsset(false)
                    assets.setSelectedAsset(asset)
                }

                assets.setAssetUploadProgress(0)    
                onComplete(true)

            }, (error) => {
                assets.setUploadingAsset(false)
                assets.setAssetUploadProgress(0)     

                if (error) {
                    assets.setUploadingError(error)
                }

                onComplete(false)
            })
            
            assets.uploadCancelCallback.current = currentUploadCancelCallback
            
        }}>{"Upload"}</YapAssetFileUploader>)
}


function removeFileExtension(filename) {
    var lastDotIndex = filename.lastIndexOf(".");
    if (lastDotIndex !== -1) {
        return filename.substring(0, lastDotIndex);
    }
    return filename;
}

const AssetPlaceholder = styled.div`    
    aspect-ratio: 1;
    background-color: #00000001;
    border: 1px dashed #00000040;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
`

const AssetTitle = styled.div`
    font-size: 13px;
    padding-top: 10px;
`

const AssetSetCell = styled.div`    
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    aspect-ratio: 1 / 1;
    text-align: center;
`

const AssetsContainer = styled.div`
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    .polymer-toolbar-actions-left {
        flex-grow: 0.33;
    }
`

const AssetsUploadInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 10px;
    width: 100%;
    p {
        font-weight: 500;
        white-space: nowrap;
    }
`

const AssetsContainerContent = styled.div`
    background-color: #f5f5f5;  
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    display: flex;
    padding: 20px;
`

const AssetsDetailContainer = styled.div`
    background-color: white;  
    margin: 20px;
    margin-top: 1px;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    display: flex;

    .asset-detail-content {
        width: -webkit-fill-available;
    }
`
