import React, { createContext, useContext, ReactNode } from 'react';
import { SetVariables, Variable,  Variables, VariablesContext, useVariables } from './Variable.tsx';


export function Defaults(props) {
    const { variables, setVariables } = useVariables();

    /**
     * Lower case key names first
     */
    let defaultProps = {...props}


    /**
     * Assign to environment if not already set or we're trying to override an environment variable
     */
    let allVariables = {...variables};
    for (const key in defaultProps) {
        if (!allVariables.hasOwnProperty(key) || key.startsWith('environment.')) {   
            allVariables[key] = defaultProps[key];
        }
    }

    return <VariablesContext.Provider value={{ variables: allVariables, setVariables: setVariables }}>{props.children}</VariablesContext.Provider>;
}
