import React, { useState, useEffect, useLayoutEffect, Suspense } from 'react'

import { Sidebar,SidebarItem, SidebarAction, SidebarSection, SidebarToolbar,SidebarToolbarAction,  YapContextMenu, YapContextMenuOption, YapContextMenuDivider } from '@yapstudios/yap-content-builder'

// Styling
import styled from 'styled-components'

import { ItemType } from './YapDesigner.js'

export function FileSidebar({ editor }) {
    const { addNewFolder, addNewComponent, addNewAssets, addNewComposer, items, allItems, packages, selectedId, setSelectedId, onDelete, onRename, sections } = editor
    let sectionItems = organizeItems(items)

    let addOptions = (path => { return [
        <YapContextMenuOption title="Add Composer"  onClick={() => {
            addNewComposer(path)
        }}/>,
        <YapContextMenuOption title="Add Component"  onClick={() => {
            addNewComponent(path)
        }}/>,
        <YapContextMenuOption title="Add Folder"  onClick={() => {
            addNewFolder(path)
        }}/>,
        <YapContextMenuOption title="Add Assets"  onClick={() => {
            addNewAssets(path)
        }}/>
    ]})

    let sectionContent = generateSections(sectionItems, 0, { addOptions })
    let packageContent = packages && packages.length ? generatePackageSections(packages) : null
    
    let addAction = (
        <SidebarToolbarAction icon="plus" title="Add"></SidebarToolbarAction>
    )

    let addMenu = (
        <YapContextMenu action={addAction} verticalAlign="top" align="left">
            {addOptions(null)}
        </YapContextMenu>
    )

    let bottomToolbar = (
        <SidebarToolbar>
            {addMenu}
        </SidebarToolbar>
    )

    return (
        <Sidebar aria-label="Component Sidebar" selectedId={selectedId} setSelectedId={setSelectedId} onDelete={onDelete} onRename={onRename} toolbarBottom={bottomToolbar}>
            {sectionContent}
            {packageContent && <YapContextMenuDivider />}   
            {packageContent}
        </Sidebar>
    )
}
const organizeItems = (items) => {
    const root = { items: [], children: {} };

    items.forEach(item => {
        const parts = (item.path ?? item.title).split('/');

        if (item.type == ItemType.folder) {
            let currentLevel = root;

            parts.forEach((part, idx) => {
                if (!currentLevel.children[part]) {
                    currentLevel.children[part] = {
                        items: [],
                        children: {},
                        path: parts.slice(0, idx + 1).join('/'),
                        name: part
                    };
                }
                currentLevel = currentLevel.children[part];
            });
        } else {
            let currentLevel = root;

            if (parts.length === 1) {
                // Item is a top-level view
                currentLevel.items.push({
                    id: item.id,
                    name: parts[0],
                    path: item.path
                });
            } else {
                // Organize item into the appropriate section
                const itemTitle = parts.pop(); // Get the actual item title
    
                parts.forEach((part, idx) => {
                    if (!currentLevel.children[part]) {
                        currentLevel.children[part] = {
                            items: [],
                            children: {},
                            path: parts.slice(0, idx + 1).join('/'),
                            name: part
                        };
                    }
                    currentLevel = currentLevel.children[part];
                });
    
                // Add the item to the final section
                currentLevel.items.push({
                    id: item.id,
                    name: itemTitle,
                    path: item.path
                });
            }
        }
    });

    return root;
}

const generatePackageSections = (packages) => {

    const items = packages.map(item => {    
        return (
            <SidebarItem
                key={item.id}
                id={"Packages/" + item.id}
                title={item.name}
                icon={"package"}
                path={item.path}
                canDelete={false}
                canRename={false}
            />
        )
    })

    return items
}

const iconType = (item) => {
    let iconType = "document"
    if (item.path.endsWith('.assets')) {
        iconType = "photo"
    }
    return iconType
}

const generateSections = (structure, level = 0, actions) => {
    const topLevelItems = structure.items.map(item => (
        <SidebarItem
            key={item.path}
            id={item.path}
            icon={iconType(item)}
            title={item.name}
            path={item.path}
            canDelete={true}
            canRename={true}
        />
    ))

    return (
        <>
            {level == 0 && topLevelItems}
            {Object.keys(structure.children).map(sectionTitle => {
                const section = structure.children[sectionTitle];
                const hasChildren = Object.keys(section.children).length > 0;

                return (
                    <SidebarSection key={sectionTitle}>
                         <SidebarItem
                            level={level + 1}
                            key={sectionTitle}
                            canExpand={true}
                            icon={"folder"}
                            id={section.path + '/'}
                            title={section.name}
                            fullTitle={section.path}
                            defaultExpanded={false}
                            canDelete={true}
                            canRename={true}
                            menuActions={ (selectedId) => { return actions.addOptions(selectedId) }}
                            canSelect={true}
                        >
                            {section.items.map(item => {
                                
                                
                                return <SidebarItem
                                    level={level + 1}
                                    key={item.id}
                                    id={item.path}
                                    icon={iconType(item)}
                                    title={item.name}
                                    fullTitle={item.path}
                                    canDelete={true}
                                    canRename={true}
                                />
                            })}
                            {hasChildren && generateSections(section, level + 1, actions)}
                        </SidebarItem>
                    </SidebarSection>
                );
            })}
        </>
    );
}
