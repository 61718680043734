import React, { useEffect, createContext, useContext } from 'react';

// Define a type for the style context
export interface RendererContextType {
    viewCallback: any;
    routeCallback: any;
    dataCallback: any;
    functionCallback: any;
    decodeViewCallback: any;
    makeView: any;
}

// Create the StyleContext with an empty default value
export const RendererContext = createContext<RendererContextType>({ viewCallback: null, makeView: null, routeCallback: null, dataCallback: null, functionCallback: null, decodeViewCallback: null });


// Custom hook to use the StyleContext
export function useRendererContext() {
    return useContext(RendererContext);
}
