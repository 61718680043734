import React, { useState, useEffect } from 'react';
import { useStyle } from '../Style.tsx';
import { useActions } from '../Actions.tsx';
import { useForegroundStyle } from '../Style.tsx';

export function Divider() {
    var style = {...useStyle()}
    const foregroundStyle = useForegroundStyle()

    var divStyle = { backgroundColor: foregroundStyle.color ?? 'black', height: '1px', width: '100%' };

    let divider = <div style={divStyle}></div>
    return <div style={{ width: '100%', height: '1px' , ...style  }}>{divider}</div>;
}