import { set } from 'firebase/database';
import React, { useState, useEffect, useRef } from 'react'
import { getFirestore, collection, doc, setDoc, getDoc, query, where, deleteDoc, documentId, getDocs, addDoc,onSnapshot } from "firebase/firestore";
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL, } from "firebase/storage";

import { db } from '../AppData.js'
import { useAuth } from './Auth.js';
import { on } from 'process';

import { v4 as uuid } from 'uuid';  

var userData = { }

// Create a root reference
const storage = getStorage();

export const useUserState = (key, defaultValue) => {
    const auth = useAuth()
    const [value, setValue] = React.useState(defaultValue)

    const loadValue = async () => {
        if (userData[auth.currentUser.uid]) {
            let value = userData[auth.currentUser.uid][key]
            setValue(value)
        } else {
            const docRef = doc(db, "user_data", auth.currentUser.uid);
            const snapshot = await getDoc(docRef);
            let dict = snapshot.data() ?? {}   
            userData[auth.currentUser.uid] = dict
            setValue(dict[key] ?? defaultValue)
        }
    }

    const setValueAndSync = async (newValue) => {
        if (userData[auth.currentUser.uid] == null) {   
            userData[auth.currentUser.uid] = {}
        }
        userData[auth.currentUser.uid][key] = newValue  

        setValue(newValue)
        const docRef = doc(db, "user_data", auth.currentUser.uid);
        await setDoc(docRef, { [key]: newValue }, { merge: true });
    }

    if (auth.currentUser) { 
        React.useEffect(() => { 
            loadValue()
        }, [auth.currentUser])  

        return [value, setValueAndSync]
    } else {
        return [value, setValueAndSync]
    }
}

export const loadProject = async (projectId) => {
    const docRef = doc(db, "projects", projectId);
    const snapshot = await getDoc(docRef);

    if (snapshot.exists()) {
        let data = snapshot.data()      
        return {...data , id: projectId }
    } else {
        console.log('No doc for project', projectId);
        return null
    }
}

export const subscribeToProjectData = (projectId, onChange) => {
    
    const docRef = doc(db, "project_data", projectId);
    //const snapshot = await getDoc(docRef);

    const unsub = onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
            let data = doc.data()      
            console.log('+ doc onchange')
            onChange({...data , id: projectId })
        } else {
            console.log('No doc for project', projectId);
            onChange(null)
        }
    
    })

    return unsub
}

export const loadProjectData = async (projectId) => {
    const docRef = doc(db, "project_data", projectId);
    const snapshot = await getDoc(docRef);

    if (snapshot.exists()) {
        let data = snapshot.data()      
        return {...data , id: projectId, updateTime: new Date() }
    } else {
        console.log('No doc for project', projectId);
        return {  id: projectId }
    }
}

export const loadUserProjects = async (userId) => {
    //console.log('useUserProjects', userId)  
    const docRef = doc(db, "user_access", userId);
    const snapshot = await getDoc(docRef);

    if (snapshot.exists()) {
        let data = snapshot.data()      
        //console.log(data)      

        let ids = data.projects ?? []

        // Define the collection and query
        const collectionRef = collection(db, "projects");
        const q = query(collectionRef, where(documentId(), "in", ids));
        
        // Execute the query
        const querySnapshot = await getDocs(q);
        
        return querySnapshot.docs.map(doc => {  
            return { ...doc.data(), id: doc.id }
        })

    } else {
        console.log('No doc for user_permissions', userId);
    }
    return []
}

export const updateProject = async (projectId, data) => {
    const docRef = doc(db, "projects", projectId);
    await setDoc(docRef, data, { merge: true });
}   

export const updateProjectData = async (projectId, data) => {
    const docRef = doc(db, "project_data", projectId);
    await setDoc(docRef, data, { merge: true });
}   

export function useProject({ projectId }) {
    const [project, setProject] = useState(null)


    const load = async () => {
        const userProject = await loadProject(projectId)
        setProject(userProject)   
    }

    useEffect(() => {   
        load()
        return () => { }
    }, [projectId])
    
    return project
}

export const loadComposerAssets = async ({ projectId }) => {    
    //console.log('useUserProjects', userId)  
    const docRef = doc(db, "project_user_assets", projectId);
    const snapshot = await getDoc(docRef);

    if (snapshot.exists()) {
        let data = snapshot.data()      
        return data
    } else {
        return {}
    }
}

const updateComposerAsset = (asset, projectId) => {
    const docRef = doc(db, "project_user_assets", projectId);

    let data = {}
    data[asset.id] = asset
    setDoc(docRef, data, { merge: true });

    return asset
}

const updateComposerAssets = (assets, projectId) => {
    const docRef = doc(db, "project_user_assets", projectId);
    setDoc(docRef, assets, { merge: false });
}

export const useUserAssets = ({ projectId }) => {

    const [assets, setAssets] = useState({})
    const [assetVersion, setAssetVersion] = useState(0)

    const assetsRef = useRef(null)
    assetsRef.current = assets  

    const loadData = async () => {
        let composerAssets = await loadComposerAssets({ projectId })
        setAssets(composerAssets)
    }

    useEffect(() => {  
        loadData()
        return () => {
            console.log('Cleanup useUserAssets')
        }
    }, [projectId]) 


    // sort by date
    let keys = Object.keys(assets ?? {}).sort((a, b) => {
        let ar = assets
        let aDate = ar[a].created?.seconds
        let bDate = ar[b].created?.seconds 
        return bDate < aDate
    })

    let assetsArray = keys.filter(key => typeof assets[key] == 'object').map((key) => {
        let asset = assets[key]
        asset.id = key
        return asset
    })

    const deleteAsset = (asset) => {
        console.log('deleteAsset', asset.id, newAssets) 

        var newAssets = assetsRef.current
        delete newAssets[asset.id]

        updateComposerAssets(newAssets, projectId)
        setAssets(newAssets)            
        setAssetVersion(assetVersion + 1)             
    }

    const updateAsset =  (asset) => {
        console.log('updateAsset', asset.id, asset) 

        updateComposerAsset(asset, projectId)

        var newAssets = assetsRef.current
        newAssets[asset.id] = asset
        setAssets(newAssets)
        setAssetVersion(assetVersion + 1)        
    }
    
    const handleUpload =  ({ file, asset, progressCallback, completeCallback, errorCallback }) => {
        return fileUploader({ file, asset, progressCallback, completeCallback, errorCallback, addedAsset: (asset) => {
            updateAsset(asset)            
        }, assetsRef, projectId })
    }

    return {
        assets: assetsArray,
        updateAsset: updateAsset,
        deleteAsset: deleteAsset,
        fileUploader: handleUpload,
    }
}


/**
 * Asset Uploader Helper
 */
const fileUploader = ({ file, asset, progressCallback, completeCallback, errorCallback, addedAsset, projectId }) => {

    // Create a reference to 'mountains.jpg'
    const fileRef = ref(storage, 'assets/' + projectId + '-' + asset.id + '-' + file.name);
    const uploadTask = uploadBytesResumable(fileRef, file);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on('state_changed',
        (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            console.log('bytesTransferred ' + snapshot.bytesTransferred)
            console.log('totalBytes ' + snapshot.totalBytes)
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes);

            // Update download UI
            progressCallback(progress);

            switch (snapshot.state) {
                case 'paused':
                    console.log('Upload is paused');
                    break;
                case 'running':
                    console.log('Upload is running');
                    break;
            }
        },
        (error) => {
            const message = error.code == 'storage/canceled' ? null : error.message
            errorCallback(message);
        },
        () => {

            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

                // Create a new asset with:
                // - The metadata from the asset from the builder
                // - The new location
                // - A new id
                var newAsset = {
                    ...asset,
                    src: downloadURL,
                    id: uuid()
                }

                // Update assets with provided callback
                if (addedAsset) {
                    addedAsset(newAsset)
                }

                // Tell the asset uploader that upload is complete
                completeCallback(newAsset);
            });
        }
    );

    return uploadTask
}

export const useProjectData = ({ projectId }) => {
    const [data, setData] = useState(null)
    const subscriptionRef = useRef(null)    

    const loadCurrentProject = async () => {
        var data = await loadProjectData(projectId)
        await didLoad(data)
    }

    const didLoad = async (projectData) => {
        var data = projectData ?? { types: [] }    
        if (data.types == null) { data.types = [] } 
        
        var types = data.types ?? []

        var resolvedPackages = []
        for (var pkg of (data.packages ?? [])) {    
            var packageData = await loadProjectData(pkg.id)
            var meta = await loadProject(pkg.id)

            let publicTypes = packageData.types.filter((item) => {
                if (item.metadata == null) { return false }
                return (item.metadata.package == 'public')
            })

            resolvedPackages.push({ ...pkg, ...meta, id: pkg.id, types: packageData.types ?? [], publicTypes: publicTypes }) 
        }
        data.packages = resolvedPackages
        data.updateTime = data.updateTime ?? new Date() 

        // Create a list of all types
        var packageTypes = []
        for (var pkg of resolvedPackages) {
            packageTypes = packageTypes.concat(pkg.types)
        }
        data.packageTypes = packageTypes
        data.allTypes = [...data.packageTypes, ...types]   
        
        setData(data)
    }

    useEffect(() => {   
        console.log('useProjectData: subscribing')
        subscriptionRef.current = subscribeToProjectData(projectId, (data) => {
            console.log('useProjectData: changed ' , projectId, data)
            didLoad(data)
        })
        return () => {
            console.log('useProjectData: unsubscribing')
            if (subscriptionRef.current) {
                subscriptionRef.current()
            }
        }

        //loadCurrentProject()
        return () => { }
    }, [projectId])
    
    return data
}

export const useProjectDataAsync = async({ projectId }) => {
    return useProjectData({ projectId })
}


export function useUserProjects({ userId }) {
    const [projects, setProjects] = useState([])

    const load = async () => {
        var projects = await loadUserProjects(userId)
        setProjects(projects)
    }

    useEffect(() => {
        load()
        return () => {  }
    }, [userId])

    return projects
}
