import React, { useEffect } from 'react';
import Editor, { useMonaco } from '@monaco-editor/react';

// Styling
import styled from 'styled-components'

// Import YapContentBuilder components
import {
    YapHeader,
    YapPage,
    YapContextMenu,
    YapContextMenuOption,
    YapHeaderButton
} from '@yapstudios/yap-content-builder'

import { Issue, IssueSeverity } from '../Issue.ts'

interface CodeEditorProps {

    // Callback function to handle issues
    setIssues: (issues: Issue[]) => void;

    // Callback function to handle content changes  
    setContent: (content: string) => void;  

    // Content of the editor
    content: string;

    // Path of the file
    path: string;

    // Language of the code 
    language: string;   

    viewMenu: React.ReactNode;  
    header: boolean;
    titleMenu: React.ReactNode;  

}

export function CodeEditor({ setIssues, content, path, setContent, language, viewMenu, header, titleMenu } : CodeEditorProps) {

    const monaco = useMonaco();

    const onEditorChange = (value, event) => {
        setContent(value);
    }

    const handleEditorValidation = (markers) => {
        // model markers
        markers.forEach((marker) => console.log('onValidate:', marker, marker.message));

        
        const issues = markers.map((marker) => {  
            return { message: marker.message, 
                     line: marker.startLineNumber,
                     severity: marker.severity <= 4 ? IssueSeverity.Warning : IssueSeverity.Error } as Issue;
        })
        
        if (setIssues) {    
            setIssues(issues);
        }
    }

    useEffect(() => {
        if (monaco) {
            console.log('here is the monaco instance:', monaco);
        }
    }, [monaco]);

    const editorComponent = <Editor
        path={path}
        height={"-webkit-fill-available"}
        defaultValue={content}
        loading={""}
        options={{
            codeLens: false,
            colorDecorators: true,
            detectIndentation: false,
            tabSize: 4,
            scrollBeyondLastLine: true,
            fontSize: 13,
            fontFamily: 'monospace',
            scrollbar: {
                useShadows: false,
                horizontalScrollbarSize: 7,
                verticalScrollbarSize: 7,
                vertical: 'auto',
                horizontal: 'auto'
            },
            minimap: { enabled: false }
        }}
        defaultLanguage={language}
        onValidate={handleEditorValidation}
        onChange={onEditorChange} />;

   

    return (
        <EditorContainer>
            {header && <OptionsContent>
                <YapHeader className="editor-header" titleLeft={true} largeTitle={false} headerActions={[viewMenu]} title={titleMenu ?? ""}></YapHeader>
            </OptionsContent>}
            <EditorContent>
                {editorComponent}
            </EditorContent>
        </EditorContainer>
    )
}
const EditorContainer = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: top;
`

const EditorContent = styled.div`
    flex-grow: 1;
    width: -webkit-fill-available;  
    height: -webkit-fill-available; 
`

const OptionsContent = styled.div`
    border-bottom: 1px solid #E3e3e3;
    width: -webkit-fill-available;
    height: 64px;
    z-index: 10;
`