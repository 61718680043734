import React, { useState, useEffect } from 'react';
import { useStyle, useForegroundStyle } from '../Style.tsx';

export function Rectangle() {
    const foregroundStyle = useForegroundStyle();

    const currentStyle = useStyle()
    const style = {
        ...currentStyle,
        flexGrow: 1,
        backgroundColor: foregroundStyle.color ?? 'black',
        width: currentStyle.width ?? '-webkit-fill-available', 
    };

    // style['width'] = style.width ?? '-webkit-fill-available';
    // if (style.height == null) {
    //     //style['flexGrow'] = 1;
    // }
    // style['height'] = style.height ?? '-webkit-fill-available';
    
    // style['backgroundColor'] = foregroundStyle.color ?? 'black';
    return <div style={style}></div>;
}