// React
import React, { useState, useEffect, useLayoutEffect, Suspense } from 'react'

import { InspectorContent, InspectorControlText, InspectorControlSelect, InspectorControlSwitch, InspectorProperty, InspectorHeader, InspectorImageWell } from '@yapstudios/yap-content-builder';

export const ComposerInspector = ({ name, model, inspector = {}, updateModel, onEditingChanged }) => {      
    const propertyItems = (inspector ? inspector.properties : null) ?? {}
    const keys = inspector['_propertyKeys'] ?? Object.keys(propertyItems)

    const createStringControl = (key, props) => {   
        return (
            <InspectorControlText placeholder={props.placeholder} multiline={props.multiline} value={model[key] ?? props.defaultValue} onChange={(v) => {
                const newModel = {...model}
                if (v.length == 0) {    
                    newModel[key] = undefined
                } else {
                    newModel[key] = v
                }
                updateModel(newModel)
            }} />
        )
    }

    const createBooleanControl = (key, props) => {   
        return (
            <InspectorControlSwitch value={model[key]}  onChange={(v) => {
                const newModel = {...model}
                newModel[key] = v
                updateModel(newModel)
            }} />
        )
    }

    const createEnumControl = (key, props) => {   

        const keys = (props.options ?? []).map((option) => {
            // if array 
            if (typeof option === 'object' && option.length == 2) {
                return option[0]
            } else {
                return option
            }
        })

        const titles = (props.options ?? []).map((option) => {  
            if (typeof option === 'object' && option.length == 2) {
                return option[1]
            } else {
                return option
            }
        })

        return (
            <InspectorControlSelect value={model[key] ?? props.defaultValue} options={titles} keys={keys} onChange={(v) => {
                const newModel = {...model}
                newModel[key] = v
                updateModel(newModel)
            }} />
        )
    }

    const createImageControl = (key, props) => {    
        const updateImage = (asset) => {
            console.log('Image ', asset)

            const newModel = {...model}
            newModel[key] = asset
            updateModel(newModel)
        }

        let image = model[key] ?? { }

        let src = image.src
        let assetModel = {
            ...image,
            src: src
        }

        return (
            <InspectorImageWell condensed={props.condensed} model={assetModel} src={src} 
            onImageSelected={(asset) => {
                updateImage({...asset, url: asset.src})
            }}
            onImageClear={() => {
                updateImage(null)    
            }}
            ></InspectorImageWell>

            // <InspectorImageWell key={"addImage"} onImageSelected={(asset) => {
            //     updateImage(asset, null)
            //  }}>
            //     <InspectorControlButton key={"addImage"} title="Add Image"/>
            // </InspectorImageWell>
        )
    }

    const propComponents = keys.map((key) => {   
        var prop = propertyItems[key]
        prop.defaultValue = prop['default']

        let control;
        let singleColumn = false;
        switch (prop.type) {
            case "string":
                singleColumn = prop.multiline ?? false;
                control = createStringControl(key, prop);
                break;
            case "boolean":
                control = createBooleanControl(key, prop);
                break;    
            case "enum":
                control = createEnumControl(key, prop);
                break;
            case "image":
                singleColumn = true
                control = createImageControl(key, prop);
                break;
    
            // Add more cases as needed for other types
            default:
                control = null;
        }

        return (  
            <InspectorProperty key={key} singleColumn={singleColumn} title={prop.title} description={prop.description}>
               {control}
            </InspectorProperty>
        )
    })

    return (
        <InspectorContent>
           
            <InspectorHeader  title={name} />

                {propComponents}
            {/* <InspectorProperty title={"Style"} >
                <InspectorControlSelect value={model.mapStyle} options={Object.values(mapStyles)} keys={Object.keys(mapStyles)} onChange={(v) => {
                    model.mapStyle = v
                    updateModel(model)
                }} />                    
            </InspectorProperty>    */}

        </InspectorContent>
    ) 
}
