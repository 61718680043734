import React from 'react';

// Modifiers
import { Padding, Margin, CornerRadius, Frame, FlexFrame, AllowsHitTesting,
        Overlay, Background, 
        ContainerRelativeFrame, ID, ForegroundStyle, 
        Blur, ScaleEffect, BackgroundBlur, Grayscale,
        Opacity, Shadow,
        Offset,
        Border, BorderWidth,
        Font, FontWeight, FontSize, LineSpacing, MultilineTextAlignment,
        UnhandledModifer,
        Disabled,
        Link, ThumbnailScale, ButtonStyle,
        Bold
        } from './Renderer/ui/Modifiers.tsx';

// Views
import { HStack, VStack, ZStack, Spacer } from './Renderer/ui/Layout.tsx';
import { Children } from './Renderer/ui/Children.tsx';
import { Button } from './Renderer/ui/Views/Button.tsx';
import { ForEach } from './Renderer/ui/ForEach.tsx';
import { Circle } from './Renderer/ui/Views/Circle.tsx';
import { Group } from './Renderer/ui/Views/Group.tsx';
import { Rectangle } from './Renderer/ui/Views/Rectangle.tsx';
import Text from "./Renderer/ui/Views/Text.tsx"
import { SetVariables, Variable, Variables } from './Renderer/ui/Variable.tsx';
import { ChildrenContext } from './Renderer/ui/Children.tsx';
import { UIFunction, Call, Argument, FunctionContext } from './Renderer/ui/Function.tsx';    
import { Script } from './Renderer/ui/Script.tsx';
import { Defaults } from './Renderer/ui/State.tsx';
import { UIImage } from './Renderer/ui/Views/Image.tsx';
import { ScrollView } from './Renderer/ui/Views/ScrollView.tsx';
import { Divider } from './Renderer/ui/Views/Divider.tsx';
import { PreviewFrame } from './Renderer/ui/PreviewFrame.tsx';
import { Component } from './Renderer/ui/Component.tsx';
import { Conditional } from './Renderer/ui/Conditional.tsx';
import { Binary } from './Renderer/ui/Binary.tsx';
import { Color } from './Renderer/ui/Views/Color.tsx';
import { LinearGradient } from './Renderer/ui/Views/LinearGradient.tsx';
import { TextField } from './Renderer/ui/Views/TextField.tsx';
import { Toggle } from './Renderer/ui/Views/Toggle.tsx';
import { ComposerChildren } from './Renderer/ui/ComposerChildren.tsx';
import { EmptyView } from './Renderer/ui/Views/EmptyView.tsx';
import { ReactRepresentable } from './Renderer/ui/ReactRepresentable.tsx';

// Actions 
import { OnTap } from './Renderer/ui/Actions.tsx';
import { RendererContent } from './Renderer/Renderer.js';
import grayscale from 'react-syntax-highlighter/dist/esm/styles/hljs/grayscale.js';

const debug = false;

// Tag / component map
export const componentsMap = {
    HStack: HStack,
    VStack: VStack,
    ZStack: ZStack,
    ScrollView: ScrollView,
    Spacer: Spacer,
    Text: Text,
    Divider: Divider,
    //Children: Children,
    Image: UIImage,
    UIImage: UIImage,
    ForEach: ForEach,
    Function: UIFunction,
    Script: Script,
    Argument: Argument,
    Call: Call,    
    Color: Color,  
    LinearGradient: LinearGradient,
    Button: Button,
    Circle: Circle,
    Defaults: Defaults,
    Rectangle: Rectangle,
    Group: Group,
    Variable: Variable,
    PreviewFrame: PreviewFrame,
    Component: Component,
    TextField: TextField,
    Toggle: Toggle,
    ComposerChildren: ComposerChildren,
    ComposerAdd: EmptyView,
    ReactRepresentable: ReactRepresentable
};

// Modifier map
export const modifiersMap = {
    padding: Padding,
    margin: Margin,
    foregroundStyle: ForegroundStyle,
    background: Background,
    opacity: Opacity,
    overlay: Overlay,
    lineSpacing: LineSpacing,
    font: Font,
    fontStyle: Font,
    fontSize: FontSize,
    fontWeight: FontWeight,
    bold: Bold,    
    border: Border,
    defaults: Defaults,
    borderWidth: BorderWidth,
    shadow: Shadow,
    blur: Blur,
    backgroundBlur: BackgroundBlur,
    grayscale: Grayscale,
    offset: Offset,
    cornerRadius: CornerRadius,
    containerRelativeFrame: ContainerRelativeFrame,
    allowsHitTesting: AllowsHitTesting,
    frame: Frame,
    onTap: OnTap,
    previewName: UnhandledModifer,
    previewFrame: UnhandledModifer,
    id: ID,
    disabled: Disabled,
    link: Link,
    scaleEffect: ScaleEffect,
    multilineTextAlignment: MultilineTextAlignment,
    thumbnailScale: ThumbnailScale,
    buttonStyle: ButtonStyle
};

export const preventDecode = {
    link: true
}

export const overridableViews = {
    ComposerChildren: true,
    ComposerAdd: true,
    Children: true
}

/**
 * YapUIDecoder decodes a JSON structure representing YapUI Directives or Variables 
 * into React components or HTML elements. It handles variables, custom components, 
 * modifiers, and external view dependencies through a view callback.
 *
 * @param {Object} json - The JSON structure representing the UI to be decoded.
 * @param {function} viewCallback - A callback function that can handle external view dependencies.
 *                                  This function is called when the element type is not recognized
 *                                  in the internal component or modifier maps.
 *
 * @returns {ReactElement|null} The decoded React element or null if the decoding fails.
 */
export function YapUIDecoder(json, viewCallback) {
    
    /**
     * Recursively decodes a JSON element into a React element or an HTML tag.
     * Handles text content, components, modifiers, variables, and external views.
     *
     * @param {Object} element - The JSON element to decode.
     * @returns {ReactElement|null} The decoded React element or null if the decoding fails.
     */  
    const decodeProp = (prop, stack, retainJSON) => {
        if (debug) { console.log('decodeProp:', prop) }

        if (prop == null) {
            return prop;

        // Ignore if already a react element
        } else if (React.isValidElement(prop)) {
            return prop
        // array
        } else if (Array.isArray(prop)) {
            return prop.map( (child) => {
                return decodeProp(child, stack + 1, retainJSON)
            })
        // dictionary
        } else if (typeof prop == 'object' && prop.type == null) {
            for (const key in Object.keys(prop)) {
                prop[key] = decodeProp(prop[key], stack + 1, retainJSON)
            } 
        // component
        } else if (typeof prop == 'object' && prop.type != null) { 
            if (retainJSON) {
                return prop
            } else {
                return decode(prop, stack + 1)
            }
        // literal
        } else {
            return prop;
        }
    }

    const decode = (element, stack, retainJSON) => {
        if (stack > 100) {
            console.error('YapUIDecoder: Stack > 100:', element)
            return;
        }
        
        /**
         * Decode plain arguments
         */
            
        // Null value.
        if (element == null) {
            return element;

        // Argument values
        } else if (typeof element == 'object' && element['_0']) {
            // todo , support multiple arguments
            return decode(element['_0'], stack + 1, preventDecode[type]);            

        } else if (typeof element == 'object' && element['rawValue']) {
            // todo , support multiple arguments
            return decode(element['rawValue'], stack + 1, preventDecode[type]);            

        // Array value
        } else if (Array.isArray(element)) {
            return element.map( (child) => {
                return decode(child, stack + 1)
            })
        } else if (element.type == null) {
            return element;
        }

        /**
         * Decode Components, Modifiers, and Variables
         */

        // get type, props, children
        
        let type = element.type;
        
        let props = element.props ? {...element.props} : {};
        // let children = props.children ? props.children.map( (child) => {
        //     return decode(child, stack + 1, preventDecode[type])    
        // }) : null;

        // Store the type name so it can be read later on in a react tree, as react component names can become obfuscated when minified
        // and this is useful to have if querying the tree for a component name.
        props['_type'] = element.type;

        // Convert variables to environment variables
        let environmentProps = extractVariables(props);

        // Rewrite props['rawValue'] to props['value']
        //if (props['rawValue']) {  
        //    props['value'] = props['rawValue']    
        //    delete props['rawValue']
        //}

        // Resolve any components in props
        if (debug) { console.log('-resolve props ', type) }

        for (const key in props) {
            props[key] = decodeProp(props[key], stack + 1, preventDecode[type])  
        }

        let children = props?.children

        // Check if view can override inbuilts
        var overriableViewCallback = null
        if (overridableViews[element.type]) {
            overriableViewCallback = viewCallback(element.type, props, children);
        }


        /**
         * Component
         */
        if (type == 'ComponentDescription') {
            return decode(element.body)
            return <Component name={element.name} body={element.body} parameters={element.parameters} previews={element.previews}></Component>

        /**
         * Binary
         */
        } else if (type == 'Binary') {

            return <Binary operator={element.operator} left={decode(element.left)} right={decode(element.right)}/>

        /**
         * Conditional
         */
        } else if (type == 'Conditional') {
            
            return <Conditional trueContent={decode(element.then)} falseContent={decode(element.else)} condition={decode(element.condition)}></Conditional>

        /**
         * Defaults
         */
        } else if (type == 'Defaults' || type == 'defaults') {
            return <Defaults {...element.constants}>{decode(element.content)}</Defaults>

        } else if (type == 'ForEach') {

            return <ForEach dataId={element.dataId} functionId={element.functionId}/>
            //return <ForEach value={decode(element.data)}>{decode(element.content)}</ForEach>

        /**
         * Variable
         */
        } else if (type == 'Variable') {
`   `
            const variableKey = String(element.name);

            if (variableKey.toLowerCase() == 'children') {
                let view = viewCallback('Children', props, children) 
                if (view) {
                    return view;
                } else {
                    return <Children></Children>
                }
            } else {
                if (debug) { console.log(`decode: variable [${variableKey}]`); }    
                return <Variable _type={type} name={variableKey}/>;
            }

        } else if (componentsMap[type] && !overriableViewCallback) {
            
            const Node = componentsMap[type];

            if (debug) { console.log(`decode: component [${type}]`, props); }

            return wrapNodeWithVariables(<Node {...props}>{children}</Node>, environmentProps);    

        /**
         * Modifier
         */
        } else if (type == 'ModifiedComponent') {

            const modifierType = element.modifier?.type;
            const modifierProps = element.modifier?.props;   

            // Convert variables to environment variables
            let modifierEnvironmentProps = extractVariables(modifierProps);

            if (debug) { console.log(`+ decode: modifier [${modifierType}]`, modifierProps, modifierEnvironmentProps); }

            // Resolve modifier content
            const content = element.content ? element.content.map( (child) => {
                return decode(child, stack + 1, preventDecode[modifierType])    
            }) : null;

            // Early exit if the modifier type is not recognized
            if (modifiersMap[modifierType] == null) {   
                return content
            }

            // Rewrite props['_0'] to props['value']
            //if (modifierProps['rawValue']) {  
            //    modifierProps['value'] = modifierProps['rawValue']    
            //    delete modifierProps['rawValue']
            //}

            // Resolve modifier props
            for (const key in modifierProps) {
                modifierProps[key] = decodeProp(modifierProps[key], stack + 1, preventDecode[type])  
            }
            
            const Node = modifiersMap[modifierType];

            return wrapNodeWithVariables(<Node {...modifierProps}>{content}</Node>, modifierEnvironmentProps);    

        /**
         * External view dependancy
         */
        } else if (viewCallback) {
            //const view = overriableViewCallback ?? viewCallback(element.props.type ?? element.type, props, children);
            const view = overriableViewCallback ?? viewCallback(element.props.type ?? element.type, element?.props, element?.props?.children);

            if (view) {
                return (
                    /** Add any plain value properties to the environment */
                    <Variables {...props} >
                        <ChildrenContext.Provider value={{content: children}}>

                            { /** Have SetVariables resolve any variables into the environment */ }
                            {wrapNodeWithVariables(<Variables >{view}</Variables> , environmentProps)}
                            
                        </ChildrenContext.Provider>
                    </Variables> 
                )
            } 

        /**
         * String element
         */
        } else {
            console.log(`decode: string [${element}]`);            

            return element;
        }
        
        console.log('decode: unknown element', element);
        return children;
    }

    return decode(json, 0);
}


/**
 * Extracts variable props from the element props and converts them to environment variables.
 * Removes any props that are identified as variables and prepares them for the environment.
 *
 * @param {Object} props - The props of a UI element.
 * @returns {Object} A dictionary of environment variables mapped by prop name.
 */
function extractVariables (props) {
    // Hash of props
    let environmentProps = {};

    // Single value
    if (props['_0'] && props['_0'].type == 'Variable') {
        environmentProps['value'] = String(props['_0']['name'])
        delete props['_0']
    } else if (props['value'] && props['value'].type == 'Variable') {
        environmentProps['value'] = String(props['value']['name'])
        delete props['value']
    } else if (props.type == 'Variable') {
        environmentProps['value'] = String(props.name)
        delete props['type']
        delete props['name']

    // Dictionary of values
    } else {
        Object.keys(props).forEach( key => {
            let value = props[key];
            
            if (value && value.type != null && value.type == 'Variable') {
                environmentProps[key] = String(props[key]['name'])
                delete props[key];
            }
        })
    }
    return environmentProps;
}
        
/**
 * Wraps a React node with a <SetVariables> component if the props contain environment variables.
 * 
 * @param {ReactElement} node - The node to wrap.
 * @param {Object} props - The props containing the environment variables.
 * @returns {ReactElement} The wrapped node if there are environment variables, otherwise the original node.
 */
function wrapNodeWithVariables (node, props) {
    if (props && Object.keys(props).length > 0) {
        return <SetVariables variables={props}>{node}</SetVariables>
    } else {
        return node
    }
}