import { YapUI } from "./YapUIGenerator";
import { Component } from "./YapUIReact";

export class DocumentYapUI {
    removeGroupKey = (tree) => {
        if (Array.isArray(tree)) {
            tree.forEach(node => this.removeGroupKey(node));
        } else if (typeof tree === 'object' && tree !== null) {
            delete tree.group;
            if (Array.isArray(tree.children)) {
                tree.children.forEach(child => this.removeGroupKey(child));
            }
        }
    }

    update(pages, components, pageRenderer) {
        //console.log('updateYapUI')

        /**
         * Generate document content
         */
        var documentContent = []
        pages.forEach( (page, idx) => {
            //console.log('page: ', page, idx);

            /**
             * Get YapUI for each page
             */
            let pagesChildren = [ ]
            page.sections.forEach( section => {

                let component = components.filter( component => { return component.type == section.type })[0]
                if (component && component.yapUI) {

                    var sectionProps = {...section.props}
                    delete sectionProps['id']
    
                    let content = component.yapUI(sectionProps, section)
                    if (content != null) {
                        let item = {...content, group: section.group}
                        if (item.group == null) {
                            delete item.group
                        }
                        pagesChildren.push(item)
                    }
                }
            })

            if (pageRenderer != null) {
                var pageProps = {...page.props}
                delete pageProps['id']

                var root = pageRenderer.yapUI(pageProps, pagesChildren, page)
                this.removeGroupKey(root)
                documentContent.push(root)
            } else {
                documentContent.push(...pagesChildren)
            }
        })
        
        /**
         * Generate root content
         */
        if (pageRenderer && pageRenderer.rootYapUI) {
            return pageRenderer.rootYapUI({}, documentContent)
        } else {
            let root = YapUI.Directive('Document', {}, documentContent)
            return root
        }   
    }   


}