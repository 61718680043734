import React, { useEffect } from 'react';
import styled from 'styled-components'
import { Function, FunctionContext } from './ui/Function.tsx';    
import { RendererContext } from './RendererContext.tsx'; 
import { YapUIDecoder } from '../YapUIDecoder.js';
import AST from '../YapUIAST.js'
import { AssetsContext, AssetsProvider } from './ui/Assets.tsx';
import { ChildrenContext } from './ui/Children.tsx';
import { ComponentContext } from './ui/Component.tsx';
import {  Environment } from './ui/Environment.tsx';
import { evalCaptureFunctions, getDomEvents } from './Utils.tsx';
import JSON5 from 'json5'
import { Variables } from './ui/Variable.tsx';
import { Defaults } from './ui/State.tsx';
import { ActionsContext , ActionsProvider } from './ui/Actions.tsx';


export function Renderer(props) {
    return (
        <RendererContentContainer ref={props.ref} className="rendererContainer">
            <RendererContent {...props} />
        </RendererContentContainer> 
    )
}


export const RendererRenderType = {
    body: "body",
    preview: "preview",
    thumbnail: "thumbnail"
}

export function RendererContent(componentProps) {

    var { updateEveryRender, runtime, componentChildren, props = null, componentName, preferredType = RendererRenderType.preview, ignorePreview = false, allowDefaultFrame = true, getAsset, frame, viewCallback, defaultsCallback, routeCallback, jsCallback,  previewIndex, environment } = componentProps

    const domEvents = getDomEvents(componentProps)

    var [result, setResult] = React.useState(null);

    const componentContent = runtime ? runtime.components[componentName] : null

    const externalCallbackRef = React.useRef(null)


    const update = () => {
        // console.log(`Renderer ${componentName}: content changed`)
        // console.log('Renderer: previews', previews)
        // console.log('Renderer: previewIndex', previewIndex)
        // console.log('Renderer: componentName', componentName)

        if (runtime == null) {  
            return null
        }

        runtime.registerEnvironment(environment)
        runtime.registerComponent('Self', componentContent, 'body')

        // Body AST
        var bodyAST = null
        
        // Use thumbnail if preferred and available
        if (preferredType == RendererRenderType.thumbnail) { 
            try {
                let ast = runtime.call(componentName, 'thumbnail', props ?? {}, componentChildren)()
                if (ast) {
                    bodyAST = ast
                }
            } catch (e) {
                console.log(`Error calling thumbnail for ${componentName}`, e)
            }
        }

        // Use previews if preferred and available
        if ((preferredType == RendererRenderType.preview || preferredType == RendererRenderType.thumbnail) && bodyAST == null) {   
            try {
                let previewAST = runtime.call(componentName, 'previews', props ?? {}, componentChildren)
                if (previewAST) {
                    if (Array.isArray(previewAST)) {  
                        bodyAST = previewAST[previewIndex ?? 0]()
                    } else {
                        bodyAST = previewAST()
                    }
                }
                console.log('Preview AST ', JSON.parse(JSON.stringify(previewAST, null, 2)))            
            } catch (e) {
                console.log(`Error calling previews for ${componentName}`, e)
            }
        }

        // If no previews
        if (bodyAST == null) {
            try {
                bodyAST = runtime.call(componentName, 'body', props ?? {}, componentChildren)()
            } catch (e) {
                console.log(`Error calling body for ${componentName}`, e)
            }            
        }

        if (bodyAST == null) {  
            return null
        }

        try {
            console.log('Body AST', JSON.stringify(bodyAST, null, 2))
        } catch (e) {

        }

        /**
         * Callback that either returns a react view from external callback or from the runtime.
         */
        const finalViewCallback = (name, props, children, environmentId) => {   
            let view = viewCallback ? viewCallback(name, props) : null
            
            //console.log('viewCallback:', name)
            
            if (view == null) {
                //console.log(' |_ view is null')
                if (environmentId) {
                    runtime.restoreEnvironment(environmentId)
                }

                try {
                    let ast = runtime.call(name, 'body', props ?? {}, children ?? [])()

                    //console.log('finalViewCallback AST', ast)
                    view = YapUIDecoder(ast, renderViewCallback)
                } catch (e) { 
                    console.log(`Error calling view [${name}]`, e)
                } 
            }
            return view
        }

        externalCallbackRef.current = finalViewCallback

        /**
         * Setup a render view callback to handle 'Self' and current component name views
         */
        const renderViewCallback = (name, props, children, environmentId) => {  
            const lowercaseName = name && name.toLowerCase ? name.toLowerCase() : null

            // if (functions[name]) {
            //     let Func = functions[name]  
            //     return <Func/>;
            // } 
            
            // if (lowercaseName == (componentName ?? "").toLowerCase()) {
            // //if (name == 'Self' || lowercaseName == (componentName ?? "").toLowerCase()) {
            //     console.log(' Rendering Self:', componentName, name)    

            //     let finalName = name == 'Self' ? componentName : name
            //     let defaultValues = (defaultsCallback ? defaultsCallback(finalName) : null) ?? {}
                
            //     let ast = null
            //     try {
            //         ast = runtime.call(finalName, 'body', props ?? {}, children ?? [])()
            //     } catch (e) { }

            //     return (
            //         <Defaults  {...defaultValues}>
            //             <AssetsProvider get={getAsset} name={finalName}>
            //                 {YapUIDecoder(ast, renderViewCallback)}
            //             </AssetsProvider>             
            //         </Defaults>               
            //     )   

            // } else 
            
            if (name == 'ComponentCall') {
                const finalName = props.name == 'Self' || name == '_Body' ? componentName : props.name
                //console.log('ComponentCall:', props.name, finalName,  props.environmentId)    
                
                return (
                        <AssetsProvider get={getAsset} name={finalName}>
                            {YapUIDecoder(children, renderViewCallback)}
                        </AssetsProvider>                 
                )
            } else if (name == '_Body') {
                //console.log('Rendering _Body:', componentName, bodyAST)  
                return (
                        <AssetsProvider get={getAsset} name={componentName}>
                            {YapUIDecoder(bodyAST, renderViewCallback)}
                        </AssetsProvider>                 
                )
            } else {

                //console.log('finalViewCallback:', name, environmentId ?? props.environmentId)
                let view = finalViewCallback(name, props, children, environmentId ?? props.environmentId)  
                
                if (view) {
                    return (
                        <AssetsProvider get={getAsset} name={name}>
                            {view}
                        </AssetsProvider>
                    )
                } else {
                    return null
                }
            }
        }

        /*
        * Apply frame if provided
        */
        var bodyContent = null
        if (allowDefaultFrame && frame) {
            let frameName = frame
            frameName = frameName.split('/').pop()

            // Route via '_Body' so the asset context is set correctly via the render callback.
            bodyContent = AST.Directive(frameName, {}, [ AST.Directive('_Body', {}, []) ])
        } else {
            //bodyContent = AST.Directive('_Body', {}, [  ])
            bodyContent = AST.Directive('_Body', {}, [  ])
        }

        return YapUIDecoder(bodyContent, renderViewCallback)
    }

    if (updateEveryRender) {
        result = update()
    } else {
        useEffect(() => {
            let r = update()
            if (r) {
                setResult(r)
            }
        }, [ frame, previewIndex, componentContent, componentName, runtime, props ])    
    }

    /**
     * Callback to allow a view to be created from a body AST.
     * Used by Button and Text styles
     * @param {*} body 
     * @param {*} props 
     * @param {*} children 
     * @returns 
     */
    const makeViewCallback = (body, props, children) => {   
        return runtime.makeComponent(body, props, children)
    }

    const dataCallback = (dataId) => {  
        let data = runtime.restoreData(dataId)  
        console.log('Data ', data)
        return data ?? []
    }

    const functionCallback = (functionId) => {  
        let f = runtime.restoreFunction(functionId)  
        console.log('function ')
        return f ?? (() => { return null })  
    }

    const decodeViewCallback = (ast) => {  
        return YapUIDecoder(ast, externalCallbackRef.current)
    }
    
    return (
        <RendererContext.Provider value={{ viewCallback: externalCallbackRef.current, 
                                           makeView: makeViewCallback, 
                                           routeCallback: routeCallback , 
                                           dataCallback: dataCallback, 
                                           functionCallback: functionCallback,
                                           decodeViewCallback: decodeViewCallback }}>
            <ChildrenContext.Provider value={{ children: [] }}>
                <FunctionContext.Provider value={{ functions: {}, functionArgs: {}, scripts: {} }}>
                    <ActionsProvider actions={domEvents }>  
                        <Environment values={environment}> 
                            {result}
                        </Environment>
                    </ActionsProvider>
                </FunctionContext.Provider>
            </ChildrenContext.Provider>
        </RendererContext.Provider>
    )
}

const RendererContentContainer = styled.div`
    display: flex;
    flex-direction: column;;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    
    & * {
        box-sizing: border-box;
    }    
`

const RendererContainer = styled.div`   

    & > :first-child {  
        width: -webkit-fill-available;
        height: -webkit-fill-available;
    }
}
`