import React from 'react';
import styled from 'styled-components'
import { remapChildren, containsNodeOfType } from '../Utils.tsx';
import { useStyle, ClearStyle, ViewStyle, StyleProvider } from './Style.tsx'
import { useActions, ClearActions } from './Actions.tsx';
import { useID } from './ID.tsx';
import { px ,getDomEvents} from "../Utils.tsx"



const shouldForwardProp = (prop) => { return prop == 'children' || prop == 'onClick' }

/**
 * HStack
 */
enum VerticalAlignment {
    top = "top",
    center = "center",
    bottom = "bottom"
}

interface HStackProps {
    spacing?: number;
    alignment?: VerticalAlignment;
    children: React.ReactNode[];
}

function HStack(props : HStackProps) {
    const { children, alignment, spacing} = props;   
    const domEvents = getDomEvents(props);  

    var style   = {...useStyle()}
    var actions = {...useActions()}
    const id    = useID();
    
    // Define the mapping from Spacer to HStackSpacer
    const mapping = {
        [Spacer as any]: <HSpacer />,
    };

    // Remap children using the utility function
    const remappedChildren = remapChildren(children, mapping);

    // If has spacer make the stack expand
    const hasSpacer = containsNodeOfType(children, Spacer, false);
    if (hasSpacer && !style['width']) {
        style['width'] = '-webkit-fill-available';
    }
    
    // Vertical alignment
    var verticalAlignment : string | null = null
    if (alignment) {
        switch (alignment) {
            case "top":
                verticalAlignment = 'flex-start';
                break
            case "bottom":
                verticalAlignment = 'flex-end';
                break
            case "center":  
            verticalAlignment = 'center';
                break;
        }
    }

    return <_HStack className={`hstack ${id}`} key={id} {...actions} {...domEvents}  verticalAlignment={verticalAlignment} gap={spacing} style={style}>
                <ClearActions>
                    <ClearStyle>
                        {remappedChildren}
                    </ClearStyle>
                </ClearActions>
            </_HStack>
}

interface _HStackProps {
    gap?: number;
    align?: string;
    verticalAlignment?: string | null;
}

const _HStack = styled('div').withConfig({ shouldForwardProp: shouldForwardProp })<_HStackProps>`
    display: flex;
    gap: ${({ gap }) => px(gap) || '7px'};
    align-items:  ${({ verticalAlignment }) => verticalAlignment || 'center'};
    justify-content:  ${({ align }) => align || 'center'};
`;


/**
 * VStack
 */
interface VStackProps {
    gap?: string;
    align?: string;
    horizontalAlignment?: string | null;
}

function VStack(props) {

    const {spacing, alignment, children} = props;   
    const domEvents = getDomEvents(props);  

    const style = {...useStyle()}
    const actions = {...useActions()}
    const id = useID();

    style['width'] = style.width ?? '-webkit-fit-content';

    // Flag if any spacer
    const hasSpacer = containsNodeOfType(children, Spacer, false);

    if (style['_container'] == 'scrollview') {
        delete style.height ;
        //style['height'] = '-webkit-fill-available';
    }

    if (hasSpacer) {
        style['height'] = '-webkit-fill-available';
    } 
    
    // Define the mapping from Spacer to HStackSpacer
    const mapping = {
        [Spacer as any]: <VSpacer />,
    };

    // Remap children using the utility function
    const remappedChildren = remapChildren([...children], mapping);

    var horizontalAlignment : string | null = null
    if (alignment) {
        switch (alignment) {
            case "leading":
                horizontalAlignment = 'flex-start';
                break
            case "trailing":
                horizontalAlignment = 'flex-end';
                break
            case "center":  
                horizontalAlignment = 'center';
                break;
        }
    }

    return <ClearStyle>
                <ClearActions>
                    <_VStack key={id} className="vstack" {...actions}  horizontalAlignment={horizontalAlignment} gap={spacing} style={style}>
                        {remappedChildren}
                    </_VStack>
                </ClearActions>
            </ClearStyle>
}

const _VStack = styled('div').withConfig({  shouldForwardProp: shouldForwardProp })<VStackProps>`
    display: flex;
    gap: ${({ gap }) => px(gap) || '7px'};
    flex-direction: column;
    justify-content:  ${({ align }) => align || 'center'};
    align-items:  ${({ horizontalAlignment }) => horizontalAlignment || 'center'};
    box-sizing: content-box;
`

enum Alignment {
    TopLeading = "topLeading",
    Top = "top",
    TopTrailing = "topTrailing",
    Leading = "leading",
    Center = "center",
    Trailing = "trailing",
    BottomLeading = "bottomLeading",
    Bottom = "bottom",
    BottomTrailing = "bottomTrailing"
}

interface ZStackProps {
    className?: any;
    children: any;
    alignment?: Alignment
}

/**
 * ZStack
 */
function ZStack(props : ZStackProps) {

    var style   = {...useStyle()}
    const actions = {...useActions()}
    const {children, alignment } = props;
    const domEvents = getDomEvents(props);  

    const id    = useID();

    // Remap children using the utility function
    const remappedChildren = React.Children.map(children, (child) => {  
        return <ZStackElement>{child}</ZStackElement>  
    });

    const alignmentMap = {
        topLeading: { justifyContent: "flex-start", alignItems: "flex-start" },
        top: { justifyContent: "flex-start", alignItems: "center" },
        topTrailing: { justifyContent: "flex-start", alignItems: "flex-end" },
        leading: { justifyContent: "center", alignItems: "flex-start" },
        center: { justifyContent: "center", alignItems: "center" },
        trailing: { justifyContent: "center", alignItems: "flex-end" },
        bottomLeading: { justifyContent: "flex-end", alignItems: "flex-start" },
        bottom: { justifyContent: "flex-end", alignItems: "center" },
        bottomTrailing: { justifyContent: "flex-end", alignItems: "flex-end" }
    };

    if (props.alignment) {
        let alignmentValue = alignmentMap[props.alignment]
        if (alignmentValue) {
            style['justifyContent'] = alignmentValue.justifyContent
            style['alignItems'] = alignmentValue.alignItems
        }
    }
    
    return (
        <_ZStack className={`zstack ${id} ` + (props.className ?? '')} {...actions} {...domEvents}  style={style} key={id}>
            <ClearActions>
                <ClearStyle>
                    {remappedChildren}
                </ClearStyle>
            </ClearActions>
        </_ZStack>
    )
}
const _ZStack = styled('div').withConfig({  shouldForwardProp: shouldForwardProp })<VStackProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`

function ZStackElement({children}) {
    const style = {...useStyle()};
    if (style.position == null) {
        style.position = 'absolute'
    }
    return <StyleProvider style={style}>{children}</StyleProvider>;
}

const _ZStackElement = styled('div')`
    position: absolute;
`

function Spacer() {
    return <HSpacer/>
}

/**
 * Spacer
 */
interface HSpacerProps {
   
}
function HSpacer() {
    var style   = {...useStyle()}
    style['flex-grow'] = 1; 

    return <_HSpacer style={style} className="hspacer" />
}

const _HSpacer = styled('div').withConfig({
    shouldForwardProp: shouldForwardProp
})<HSpacerProps>`
    flex-grow: 1;
`


/**
 * Spacer
 */
interface VSpacerProps {
   
}
function VSpacer() {
    var style   = {...useStyle()}
    return <_VSpacer style={style} className="vspacer" />
}

const _VSpacer = styled('div').withConfig({
    shouldForwardProp: shouldForwardProp
})<VSpacerProps>`
    flex-grow: 1;
`

export { HStack, VStack, ZStack, Spacer };