import React, { useState, useEffect } from 'react';
import { StyleProvider, StyleContext, useStyle } from '../Style.tsx';
import { useActions } from '../Actions.tsx';
import { useRendererContext } from '../../RendererContext.tsx';    
import { Variables , useVariables} from '../Variable.tsx';
import { useEnvironmentStyle } from '../Style.tsx';
import AST from '../../../YapUIAST.js';
import Text from './Text.tsx';  

export function Button({ rawValue, label, environmentId }) {
    const environmentStyle = useEnvironmentStyle();
    const style = {...useStyle()}
    const actions = {...useActions()}
    const rendererContext = useRendererContext();
    
    /** Get variables and functions */
    const { variables } = useVariables();

    var buttonStyle = environmentStyle.buttonStyle

    var buttonLabel = rawValue ?? label

    // If a string is passed turn the string into a Text component
    if (typeof buttonLabel == 'string') { 
        buttonLabel = Text({ rawValue: buttonLabel })     
    }

    if (buttonStyle && rendererContext.viewCallback ) {

        // Create a wrapper for the react component that will be the contents of the button
        let representable = () => { return AST.Directive('ReactRepresentable', { rawValue: buttonLabel } ) }

        // Turn that wrapper into a view that can be used by the button style javascript.
        let view = rendererContext.makeView(representable)

        // Call the button style 
        let buttonStyleView = rendererContext.viewCallback(buttonStyle, { label: view }, [], environmentId);
        
        if (buttonStyleView) {
            style['textAlign'] = style['textAlign'] || 'center'
            return <StyleProvider style={style}>{buttonStyleView}</StyleProvider>
        }
    
    } else {
        return (
            buttonLabel
        );
    }
}
