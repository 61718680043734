const JS = {} 

/**
 * Generates a top level entry point in a JS file
 * @param {*} name 
 * @param {*} props 
 * @param {*} children 
 * @returns 
 */
JS.EntryPoint = (name, props, children) => {
    let js = ''
    let p = JSON.stringify(props ?? {})   
    
    js += `const ${name} = (props = ${p}, children = []) => `

    let childrenContent = children.map( child => {  
        return child.generate(1)
    }).join(",\n") 

    if (children.length > 1) {
        js += "\n"
        js += "\t" + ' Group([' + "\n"
        js += "\t" + childrenContent
        js += "\t" + '])' + "\n"
    } else {
        if (children[0].implictReturn) {
            js += "\n"
            js += "\t" + childrenContent
        } else {
            js += " { " + "\n"
            js += "\t" + childrenContent
            js += " } " + "\n"
        }
    }

    js += "\n"

    return js
}

const tabs = (level) => {   
    return "\t".repeat(level)
}

/**
 * Generates JSON content for a function body
 * @param {} json 
 * @returns 
 */
JS.JSON = (json = {}) => {
    const generate = (level) => {

        let jsonStr = JSON.stringify(json, null, tabs(level + 1))

        // remove last character
        jsonStr = jsonStr.substring(0, jsonStr.length - 1)

        let js = '\n'
        js += tabs(level) + 'return ' 
        js += tabs(level) +  jsonStr
        js += tabs(level) +  "}" + "\n"
        js += "\n"
        return js
    }
    return { generate: generate, implictReturn: false }
}

/**
 * Generates component view content for a function body
 * @param {} json 
 * @returns 
 */
JS.Directive = (name, props = {}, children) => {
    let js = '';

    const generate = (level) => {
        let p = JSON.stringify(props ?? {})   

        let childrenContent = children.map( child => {
            let content = child.generate(level + 2)  
            return tabs(level + 1) + content 
        }).join(",\n\n") 

        if (children.length > 0) {
            js += `${name}(${p}, [ `   + "\n"
            js += "\n"
            js += `${childrenContent}`    + "\n"
            js += "\n"
            js += "\t" + '])' 
        } else {
            js += `${name}(${p}, [])` 
        }
        return js
    }

    return { generate: generate, implictReturn: true }
}

export default JS