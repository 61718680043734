const ASTUtil = {}

ASTUtil.findModifier = (json, modifierName, deep) => {
    let result = null;

    // Helper function to recursively traverse the JSON structure
    const traverse = (node) => {

        if (typeof node !== 'object' || node === null) {
            return;
        }

        let isModifier = node.type == 'ModifiedComponent'
        if (!isModifier) {
            if (!deep) {
                return;                
            }
        }

        // Check if the current node's type exists, has a lowercase first character, and matches the modifier name
        if (node.modifier?.type === modifierName) {
            result = node.modifier;
            return;
        }

        let children = node.props?.children ?? node.modifier?.content?.children

        // If the node has children, traverse through them
        if (Array.isArray(children)) {
            for (const child of children) {
                traverse(child);
                if (result) {
                    return; // If we found the modifier, stop further traversal
                }
            }
        }

    };

    traverse(json);

    return result;
};

ASTUtil.findFirstComponent = (json) => {
    let result = null;  

    const traverse = (node) => {    
        let isModifier = node.type && /^[a-z]/.test(node.type)
        if (isModifier) {
            for (const child of node.children) {
                traverse(child) 
            }
        } else {
            result = node
        }     
    }
    traverse(json);

    return result; 
}

ASTUtil.findComponent = (json) => {
    if (json && String(json.type).toLowerCase() == 'componentdescription') {
        return json
    } else {
        return null
    }
}

export default ASTUtil