import React, { createContext, useContext, ReactNode } from 'react';
import { useStyle, StyleProvider } from '../Style.tsx';
import { SetVariables, useVariableNode } from '../Variable.tsx';
import { useAssets , getAssetColor } from '../Assets.tsx';

export function useColorNode(value : React.ReactNode) {
    const assets = useAssets();

    // Check if the value is a React element of type 'Color'
    if (React.isValidElement(value)) {

        if (value.type === Color) {

            const v = value.props['rawValue'] ?? value.props['value'] ?? value.props['children'];  

            var colorValue = useVariableNode(v);

            const colorAsset = assets.get(colorValue)    
            if (colorAsset) {
                const color = getAssetColor(colorAsset);
                if (color) {
                    return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
                }
            } 

            return colorValue

        } else if (value.type == SetVariables) {
            let content = value.type(value.props)
            if (content) {
                return content['props'].children[0].props.value
            }
            return content
        } else {
            return value
        }   
    } else if (typeof value === 'string') {
        return value
    } else {
        return null
    }
}

export function Color({ rawValue, children, content }) {
    var style = {...useStyle()};
    const v = rawValue ?? children
    var firstChild = useVariableNode(v ? v : null);
    const assets = useAssets();

    let colorAsset = assets.get(firstChild)    
    if (colorAsset) {
        let color = getAssetColor(colorAsset);
        if (color) {
            style.backgroundColor = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
        } else {
            style.backgroundColor = '';
        }
    } else {
        style.backgroundColor = firstChild as string;
    }

    // If content is passed, apply the color as a background color style
    if (content) {  
        return <StyleProvider style={style}>{content}</StyleProvider>;            

    // Otherwise present the color
    } else {
        return <div style={style}>&nbsp;</div>;
    }

}