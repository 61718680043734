import React from 'react';
import { useVariableNode } from './Variable.tsx';

export function Binary({ operator, left, right }) {
    const primaryVal   = useVariableNode(left);

    // Test the value in the environment. 
    // Still return the <Variable> react element to render itself.
    if (operator == '??') { 
        return <>{primaryVal ? left : right}</>
    } else {
        return left;
    }
}
