import { useEffect, useState } from 'react';
import { getDatabase, ref, set , onValue} from "firebase/database";

export const useAppMirror = (id) => {
    useEffect(() => {
        if (!id) {
            console.warn('No ID provided to useAppMirror hook.');
        }
    }, [id]);

    const syncData = async (path, data) => {
        if (!id) return;

        const db = getDatabase();
        if (!db) {
            throw new Error('Firebase database not initialized');
        }

        const dataRef = ref(db, `mirror/${id}/${path}`);

        try {
            await set(dataRef, data);
        } catch (err) {
            console.error(`Failed to sync data to ${path}:`, err.message);
        }
    };

    const syncSelected   = (data)   => syncData('current', data);
    const syncComponents = (data) => syncData('components', data);
    const syncPreview    = (data)    => syncData('preview', data);
    const syncFrame      = (data)    => syncData('frame', data);

    return { syncSelected, syncComponents, syncPreview, syncFrame };
};

export const useAppMirrorPreview = (id) => {
    const [current, setCurrent] = useState(null);  // Renamed focused to current
    const [components, setComponents] = useState(null);
    const [preview, setPreview] = useState(null);
    const [frame, setFrame] = useState(null);  // Added state for frame
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!id) {
            console.warn('No ID provided to useAppMirrorPreview hook.');
            return;
        }

        const db = getDatabase();
        const currentRef = ref(db, `mirror/${id}/current`);  // Path for current
        const componentsRef = ref(db, `mirror/${id}/components`);
        const previewRef = ref(db, `mirror/${id}/preview`);
        const frameRef = ref(db, `mirror/${id}/frame`);  // Added path for frame

        const unsubscribeCurrent = onValue(currentRef, (snapshot) => {
            const data = snapshot.val();
            setCurrent(data);
        }, (err) => {
            setError(err.message);
        });

        const unsubscribeComponents = onValue(componentsRef, (snapshot) => {
            const data = snapshot.val();
            setComponents(data);
        }, (err) => {
            setError(err.message);
        });

        const unsubscribePreview = onValue(previewRef, (snapshot) => {
            const data = snapshot.val();
            setPreview(data);
        }, (err) => {
            setError(err.message);
        });

        const unsubscribeFrame = onValue(frameRef, (snapshot) => {
            const data = snapshot.val();
            setFrame(data);
        }, (err) => {
            setError(err.message);
        });

        // Cleanup subscriptions when the component unmounts or id changes
        return () => {
            unsubscribeCurrent();
            unsubscribeComponents();
            unsubscribePreview();
            unsubscribeFrame();
        };
    }, [id]);

    return { current, components, preview, frame, error };  // Added frame to return values
};