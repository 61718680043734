const AST = {} 

AST.Directive = (name, args = {}, children) => {
    var props = {...args}
    props['children'] =  children ?? []
    
    return {
        'type': name,
        'props': props
    }
}

AST.ModifiedContent = (modifier, component) => {

    const content = (Array.isArray(component) ? component : [component])

    return {
        'type': 'ModifiedComponent',
        'modifier': {...modifier},
        'content': content
    }
}

AST.ForEach = (dataId, functionId, count)  => {
    return {
        'type' : 'ForEach',
        'dataId' : dataId,
        'count' : count,
        'functionId' : functionId
    }
}

export default AST