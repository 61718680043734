import React, { createContext, useContext, ReactNode } from 'react';
import { useStyle, StyleProvider, ForegroundStyleProvider } from './Style.tsx';
import styled from 'styled-components'
import JSON5 from 'json5'
import { IDContext } from './ID.tsx'
import { pt, px, pxnull, asNumber } from '../Utils.tsx'
import { ZStack } from './Layout.tsx';
import { Color, useColorNode } from './Views/Color.tsx';
import { Variables, SetVariables, useVariables } from './Variable.tsx';
import { useActions , ActionsProvider } from './Actions.tsx';
import { useRendererContext } from '../RendererContext.tsx';    
import { EnvironmentStyleProvider , useEnvironmentStyle } from './Style.tsx'; 

// Define a type for the Asset object
interface Padding {
    rawValue?: string, 
    length?: string, 
    leading?: string, 
    trailing?: string, 
    top?: string, 
    bottom?: string, 
    horizontal? : string,
    vertical? : string,
    children : React.ReactNode
}

export function Padding({ rawValue, length, leading, trailing, top, bottom, horizontal, vertical, children } : Padding) {
    // Initialize the style object with the current style values
    var style = { ...useStyle() };
    
    const currentInsets = {
        left: asNumber(style['paddingLeft'])  + asNumber(style['marginLeft']),
        right: asNumber(style['paddingRight']) + asNumber(style['marginRight']),
        top:  asNumber(style['paddingTop']) + asNumber(style['marginTop']),
        bottom:  asNumber(style['paddingBottom'])  + asNumber(style['marginBottom']),
    }

    var insets = {
        left: 0,
        right: 0,
        top:  0,
        bottom: 0
    }

    // Apply the padding value to all sides if provided
    if (rawValue) {
        insets = { left:  asNumber(rawValue), right: asNumber(rawValue), top: asNumber(rawValue), bottom: asNumber(rawValue) };
    }

    if (length) {
        insets = { left: asNumber(length), right: asNumber(length), top: asNumber(length), bottom: asNumber(length) };        
    }

    // Override specific sides if individual values are provided
    if (leading) {
        insets.left = asNumber(leading);
    }

    if (trailing) {
        insets.right = asNumber(trailing);  
    }

    if (horizontal) {
        insets.left = asNumber(horizontal);
        insets.right = asNumber(horizontal);
    }

    if (vertical) {
        insets.top = asNumber(vertical);
        insets.bottom = asNumber(vertical);
    }

    if (top != null) {
        insets.top = asNumber(top);     
    }
    
    if (bottom) {
        insets.bottom = asNumber(bottom);  
    }

    const finalInsets = {
        left: currentInsets.left + insets.left,
        right: currentInsets.right + insets.right,
        top: currentInsets.top + insets.top, 
        bottom: currentInsets.bottom + insets.bottom    
    }

    style.marginLeft  = finalInsets.left < 0 ? px(finalInsets.left) : 0;    
    style.paddingLeft = finalInsets.left > 0 ? px(finalInsets.left) : 0;    

    style.marginRight  = finalInsets.right < 0 ? px(finalInsets.right) : 0;    
    style.paddingRight = finalInsets.right > 0 ? px(finalInsets.right) : 0;    

    style.marginTop  = finalInsets.top < 0 ? px(finalInsets.top) : 0;    
    style.paddingTop = finalInsets.top > 0 ? px(finalInsets.top) : 0;    

    style.marginBottom  = finalInsets.bottom < 0 ? px(finalInsets.bottom) : 0;    
    style.paddingBottom = finalInsets.bottom > 0 ? px(finalInsets.bottom) : 0;    

    // style.marginLeft  = px(finalInsets.left)
    // style.marginRight  = px(finalInsets.right);  
    // style.marginTop  = px(finalInsets.top) 
    // style.marginBottom  = px(finalInsets.bottom)  
    
    return <StyleProvider style={style}>{children}</StyleProvider>;
}

export function Margin({ rawValue, length, leading, trailing, top, bottom, horizontal, vertical, children } : Padding) {
    // Initialize the style object with the current style values
    var style = { ...useStyle() };

    const currentInsets = {
        left: asNumber(style['marginLeft']),
        right: asNumber(style['marginRight']),
        top:  asNumber(style['marginTop']),
        bottom:  asNumber(style['marginBottom'])
    }

    var insets = {
        left: 0,
        right: 0,
        top:  0,
        bottom: 0
    }

    // Apply the padding value to all sides if provided
    if (rawValue) {
        insets = { left:  asNumber(rawValue), right: asNumber(rawValue), top: asNumber(rawValue), bottom: asNumber(rawValue) };
    }

    if (length) {
        insets = { left: asNumber(length), right: asNumber(length), top: asNumber(length), bottom: asNumber(length) };        
    }

    // Override specific sides if individual values are provided
    if (leading) {
        insets.left = asNumber(leading);
    }

    if (trailing) {
        insets.right = asNumber(trailing);  
    }

    if (horizontal) {
        insets.left = asNumber(horizontal);
        insets.right = asNumber(horizontal);
    }

    if (vertical) {
        insets.top = asNumber(vertical);
        insets.bottom = asNumber(vertical);
    }

    if (top != null) {
        insets.top = asNumber(top);     
    }
    
    if (bottom) {
        insets.bottom = asNumber(bottom);  
    }

    const finalInsets = {
        left: currentInsets.left + insets.left,
        right: currentInsets.right + insets.right,
        top: currentInsets.top + insets.top, 
        bottom: currentInsets.bottom + insets.bottom    
    }

    style.marginLeft  = px(finalInsets.left)
    style.marginRight  = px(finalInsets.right);  
    style.marginTop  = px(finalInsets.top) 
    style.marginBottom  = px(finalInsets.bottom)  

    return <StyleProvider style={style}>{children}</StyleProvider>;
}

/**
 * Border
 */
export function Border({ rawValue, color, width, children } : { rawValue?: string, color?: string, width?: string, children: React.ReactNode[] }) {
    var style = {...useStyle()};

    style.borderColor = color ?? rawValue;
    style.borderStyle = 'solid';
    style.borderWidth = style.borderWidth ?? pxnull(width) ?? px("1");

    return <StyleProvider style={style}>{children}</StyleProvider>;
}

export function BorderWidth({ rawValue, children } : { rawValue?: string, children: React.ReactNode[] }) {
    var style = {...useStyle()};

    if (rawValue) {
        //style.borderColor = style.borderColor ?? 'black';
        //style.borderStyle = style.borderStyle ?? 'solid';
        style.borderWidth = px(rawValue);
    }

    return <StyleProvider style={style}>{children}</StyleProvider>;
}

/**
 * CornerRadius
 */
export function CornerRadius({ rawValue, children } : { rawValue?: string, children: React.ReactNode[] }) {
    const style = {...useStyle()};

    if (rawValue) {
        style.borderRadius = rawValue ? px(rawValue) : style.borderRadius;
        style.overflow = 'hidden';
    }

    return <StyleProvider style={style}>{children}</StyleProvider>;
}

/**
 * Blur
 */
export function Blur({ rawValue, radius, children }: { rawValue?: number, radius?:number, children: React.ReactNode[] }) {
    const style = { ...useStyle() };

    const v = rawValue ?? radius;
    if (v && v > 0) {
        style.filter = `blur(${px(v)})`;
    }

    return <StyleProvider style={style}>{children}</StyleProvider>;
}



/**
 * Scale
 */
export function ScaleEffect({ rawValue, children }: { rawValue?: number, children: React.ReactNode[] }) {
    const style = { ...useStyle() };

    if (rawValue != null && rawValue > 0) {
        style.transform = `scale(${rawValue})`;
    }

    return <StyleProvider style={style}>{children}</StyleProvider>;
}

export function BackgroundBlur({ rawValue, children }) {
    const style = { ...useStyle() };
    style.backdropFilter = `blur(${rawValue ?? 20}px)`;    
    return <StyleProvider style={style}>{children}</StyleProvider>;
}

export function Grayscale({ rawValue, children }) { 
    const style = { ...useStyle() };
    if (rawValue > 0) {
        style.filter = `grayscale(${rawValue}%)`;
    }
    return <StyleProvider style={style}>{children}</StyleProvider>;
}

export function ThumbnailScale({ rawValue, width, height, children }: { rawValue?: string, width?: number, height?: number, children: React.ReactNode[] }) {
    const style = { ...useStyle() };
    const { variables, setVariables } = useVariables();

    
    //let thumbnailWidth  = parseFloat(variables['display.size.width']) ?? 150.0
    let thumbnailWidth  = parseFloat(variables['thumbnail.size.width']) ?? 150.0
    let thumbnailHeight = parseFloat(variables['thumbnail.size.height']) ?? 150.0
    
    let widthScaleAmount = thumbnailWidth / (width ?? 150)
    let heightScaleAmount = thumbnailHeight / (height ?? 150)

    if (widthScaleAmount < heightScaleAmount) { 
        style.transform = `scale(${widthScaleAmount})`;
    } else {
        style.transform = `scale(${heightScaleAmount})`;
    }

    return <StyleProvider style={style}>{children}</StyleProvider>;
}

/**
 * Background
 */
export function Background({ rawValue, children }: { rawValue?: string | React.ReactNode, children: React.ReactNode[] }) {
    const style = { ...useStyle() };
    
    let v = rawValue;  
    //return 'background'
    // Check if the value is a React element of type 'Color'

    if (React.isValidElement(v)) {
        // if (v.type === SetVariables) 
        //     return React.cloneElement(v, { props: { ...v.props, content: children }} as any);   
        if (v.type === Color) {
            const colorValue = useColorNode(v);
            return <StyleProvider style={{...style, backgroundColor: colorValue as any }}>{children}</StyleProvider>;
    
            //return React.cloneElement(v, { ...v.props, content: children } as any);   
        } else {
            return (
                <ZStack>
                    {v}
                    {children}
                </ZStack>
            )
        }   
    } else if (typeof v === 'string') {
        return <StyleProvider style={{ ...style, backgroundColor: rawValue as any }}>{children}</StyleProvider>;
    } else {
        return null
    }

} 

/**
 * Overlay
 */
export function Overlay({ rawValue, children } : { rawValue?: string, content, children: React.ReactNode[] }) {
    return (
            <ZStack className="overlay">
                {rawValue}                   
                {children}             
            </ZStack>
    )
}

/**
 * ForegroundStyle
 */
export function ForegroundStyle({ rawValue, children } : { rawValue?: string, children: React.ReactNode[] }) {
    const colorValue = useColorNode(rawValue);
    const style = { color: colorValue };
    return <ForegroundStyleProvider style={style}>{children}</ForegroundStyleProvider>;
}

/**
 * Shadow
 */
export function Shadow({ rawValue, radius, x, y, color, children } : { rawValue?: string, radius: string, x: string, y: string, color: string, children: React.ReactNode[] }) {
    const style = {...useStyle()};
    const shadowColor = useColorNode(color ?? 'rgba(0, 0, 0, 0.1)');    

    var shadowRadius = radius ?? "30"
    var shadowX = x ?? "0"
    var shadowY = y ??  String(parseInt(shadowRadius) / 3)
    
    if (rawValue) {
        shadowRadius = rawValue;
    }

    style.boxShadow = `${px(shadowX)} ${px(shadowY)} ${px(shadowRadius)} ${shadowColor}`;

    return <StyleProvider style={style}>{children}</StyleProvider>;
}


/**
 * Frame
 */
export function Frame({ maxwidth, maxWidth, width, height, minwidth, minWidth, maxHeight, maxheight, minHeight, minheight, children, alignment}) {
    const style = {...useStyle()};

    // var minWidth = minwidth;
    // var minHeight = minheight;
    // var maxWidth = maxwidth;
    // var maxHeight = maxheight;

    if (maxWidth == 'infinity' || maxWidth == Infinity) {
        style.width = '-webkit-fill-available';
    } else if (maxWidth) {
        style['maxWidth'] = px(maxWidth);
    }

    if (minWidth) {
        style['minWidth'] = px(minWidth);
    }

    if (width) {
        style.width = px(width);
    }

    if (maxHeight == 'infinity' || maxHeight == Infinity) {
        style.height = '-webkit-fill-available';
        if (style['_container'] == 'scrollview') {
            style.height = '100%';
        }
    }  else if (maxHeight) {
        style['maxHeight'] = px(maxHeight);
    } 

    if (minHeight) {
        style['minHeight'] = px(minHeight);
    }

    if (height) {
        style.height = px(height);
    }

    if (alignment) {
        switch (alignment) {
            case 'leading':
                style.alignItems = 'flex-start';
                break;
            case 'trailing':
                style.alignItems = 'flex-end';
                break;
            case 'center':
                style.alignItems = 'center';
                break;
            case 'top':
                style.justifyContent = 'flex-start';
                break;
            case 'bottom':
                style.justifyContent = 'flex-end';
                break;
            case 'center':
                style.justifyContent = 'center';
        }
    }

    return <StyleProvider style={style}>{children}</StyleProvider>;
}

export function FlexFrame(props) {  
    return Frame(props);    
}

/**
 * ContainerRelativeFrame
 */
export function ContainerRelativeFrame({ rawValue, children }) {
    return children
}

/**
 * ID
 */
export function ID({ rawValue, children }) {
    return <IDContext.Provider value={{id: rawValue}}>{children}</IDContext.Provider> 
}

/**
 * Disabled
 */
export function Disabled({ rawValue, children }) {
    if (rawValue == null || rawValue == true) {
        return <Variables disabled={true}>{children}</Variables>
    } else {
        return children
    }
}

/**
 * Font
 */
export function Font({ rawValue, children }) {
    const v = rawValue;
    const style = {...useStyle()};

    const fontStyle = {
        title: [28, '700'],          // Title, typically bold and larger
        largeTitle: [34, '900'],     // Large title, used for prominent headings
        title1: [28, '700'],         // Title 1, a bit larger
        title2: [22, '600'],         // Title 2, smaller than Title 1
        title3: [20, '600'],         // Title 3, smaller than Title 2
        headline: [17, '600'],       // Headline, typically bold text
        subheadline: [15, '400'],    // Subheadline, smaller than headline
        body: [17, '400'],           // Body, regular text size
        callout: [16, '400'],        // Callout, slightly smaller than body
        footnote: [13, '400'],       // Footnote, smaller text
        caption: [12, '400'],        // Caption, small, regular text
        caption2: [11, '400'],       // Caption 2, even smaller
    };

    const fontDefinition = fontStyle[v];  

    if (fontDefinition) {
        style['_fontStyle'] = v;
        style.fontSize = px(fontDefinition[0]);
        style.fontWeight = style.fontWeight ?? fontDefinition[1];
    }
    
    return <StyleProvider style={style}>{children}</StyleProvider>;
}

export function FontSize({ rawValue, size, children }) { 
    const style = {...useStyle()};
    style.fontSize = px(rawValue ?? size);
    return <StyleProvider style={style}>{children}</StyleProvider>;
}

export function FontWeight({ rawValue, children }) {
    const style = {...useStyle()};
    const fontWeightMap = {
        bold: '700',
        semibold: '600',
        medium: '500',
        regular: '400',
        light: '300',
        thin: '200',
        ultralight: '100',
        black: '900',
        heavy: '800'
    };

    var weightDefinition = fontWeightMap[rawValue];   
    if (weightDefinition) {
        style.fontWeight = weightDefinition
    }

    return <StyleProvider style={style}>{children}</StyleProvider>;
}   

export function Bold({ rawValue, children }) {
    const style = {...useStyle()};
    style.fontWeight = '700';
    return <StyleProvider style={style}>{children}</StyleProvider>;
}

export function LineSpacing({ rawValue, children }) {
    const style = {...useStyle()};
    const ptValue = pt(rawValue);
    if (ptValue) {
        style.lineHeight = ptValue;
    }
    
    return <StyleProvider style={style}>{children}</StyleProvider>;
}   

export function MultilineTextAlignment({ rawValue, children }) {   
    const style = {...useStyle()};
    style.textAlign = rawValue;
    return <StyleProvider style={style}>{children}</StyleProvider>; 
}

/**
 * Hit Testing
 */
export function AllowsHitTesting({ rawValue, children }) {
    const style = {...useStyle()};
    style.pointerEvents = (rawValue == true || rawValue == 'true') ? 'all' : 'none';
    return <StyleProvider style={style}>{children}</StyleProvider>;
}

export function UnhandledModifer({ children }) {
    return children
}

/**
 * Opacity
 * @returns 
 */
export function Opacity({ rawValue, children }) {
    const style = {...useStyle()};
    style.opacity = rawValue;
    return <StyleProvider style={style}>{children}</StyleProvider>;
}

/**
 * Offset
 * @returns 
 */
export function Offset({ x, y , children }) {
    const style = {...useStyle()};
    if (x != null) {
        style.left = x;
    }
    if (y != null) {    
        style.top = y;
    }
    if (x != null || y != null) {
        style.position = 'relative';
    }

    return <StyleProvider style={style}>{children}</StyleProvider>;
}

/**
 * Link
 * @param param0 
 * @returns 
 */
export function Link({ rawValue, children }) {
    const style = {...useStyle()};

    var actions = {...useActions()};
    const rendererContext = useRendererContext();

    const onClick = () => {
        if (rendererContext && rendererContext.routeCallback) {
            rendererContext.routeCallback(rawValue);
        }
    }

    style.cursor = 'pointer';
    actions['onClick'] = onClick;

    return (
            <StyleProvider style={style}>
                <ActionsProvider actions={actions}>{children}</ActionsProvider>
            </StyleProvider>
            )
}

export function ButtonStyle({ rawValue, children }) {
    const style = {...useEnvironmentStyle()};
    style.buttonStyle = rawValue
    return <EnvironmentStyleProvider style={style}>{children}</EnvironmentStyleProvider>;
}   