import * as React from 'react'
import { useYapPageState } from '../uikit-components/Page'
import HeaderButton from '../uikit-components/HeaderButton'

function HeaderBackButton({ title, onClick }) {
    return <span className="polymer-back-button" onClick={onClick}></span>
}

function HeaderCloseButton({ onClick }) {
    return <HeaderButton key={"close"} onClick={onClick}>{"Close"}</HeaderButton>
}

function Header({ headerActions, headerActionsLeft, largeTitle, className, titleLeft, fixed, title, backTitle, backAction, onBack, onClose, children }) {
    const state = useYapPageState()
    const headerTitle = title ?? state.headerTitle ?? "Title"
    
    const hasBack = onBack ?? onClose ?? state.onBack ?? state.backAction ?? backAction

    var showLargeTitle = true
    if (state.prefersLargeTitle != null) { showLargeTitle = state.prefersLargeTitle }
    if (largeTitle != null) { showLargeTitle = largeTitle }

    React.useEffect( () => {
        if (fixed) {
            state.setHasFixedHeader(fixed)
        }
    }, [fixed])

    var backButton = <HeaderBackButton title={backTitle} onClick={() => {
        state.setHeaderActions(null)
        if (state.onBack) {
            state.onBack()
        }
        if (onBack) {
            onBack()
        }
    }} />


    if (onClose) {
        backButton = <HeaderCloseButton onClick={onClose}/>
    } else {
        backButton = state.backAction ?? backAction ?? backButton
    }

    const leftActions = (state.headerActionsLeft ?? []).concat(headerActionsLeft ?? [])
    const rightActions = (headerActions ?? []).concat(state.headerActions ?? [])

    const actionsRight = rightActions.length > 0 ? <span className="polymer-header-actions">{rightActions}</span> :  <div className="polymer-back-button-empty">&nbsp;</div>

    const unpublished = <span className={"subtitle"}>— Unpublished Changes</span>
    const editedContent = state.hasUnsavedChanges ? unpublished : null

    const content = children ?? <h1>{headerTitle}{editedContent}</h1>

    var titleClass = showLargeTitle ? "polymer-header-large-title" : "polymer-header-regular-title centered "
    if (titleLeft) {
        titleClass = "polymer-header-regular-title"
    }
    const headerClassName = `polymer-header ${titleClass} ` + (className ?? "") 

    const emptyLeft = null// largeTitle != true && <div className="polymer-back-button-empty"></div>
    
    const actionsLeftContent = (
        [
            hasBack ? backButton : emptyLeft ,
            leftActions
        ]
    )
    
    const actionsLeft = (
        <span className="polymer-header-actions-left">
            {actionsLeftContent}
        </span>
    )

    const hasActionsLeft = hasBack || actionsLeft.length > 0 

    if (showLargeTitle) {
        if (hasActionsLeft) {
            return (
                <div className={headerClassName}>
                    <div style={{'flexGrow' : 1}}>
                        
                        <div className={`polymer-header-content ${state.containerClassName}`}>
                            {actionsLeft}&nbsp;{actionsRight}
                        </div>

                        {content}

                    </div>
                </div>
            )  
        } else {
            return (
                <div className={headerClassName}>
                    <div className={`polymer-header-content ${state.containerClassName}`}>
                        {content}{actionsRight}
                    </div>
                </div>
            )                     
        }
    } else {
        var leftContent = actionsLeft
        var centerContent = content
        if (titleLeft) {
            leftContent = <div className={"polymer-header-actions-left polymer-header-actions-left-with-title"} >{actionsLeftContent}{centerContent}</div>
            centerContent = <div >&nbsp;</div>
        }
        return (
            <div className={headerClassName}>
                <div className={`polymer-header-content ${state.containerClassName}`}>
                    {leftContent}{centerContent}{actionsRight}
                </div>
            </div>
        )
    }
}

export default Header
