// React
import React, { useState, useEffect, useLayoutEffect, Suspense } from 'react'

// Styling
import styled from 'styled-components'

// Content Builder Components
import { View, Section, Stack, Text, Image  } from '@yapstudios/yap-content-builder';

import AST from '../YapUI/YapUIAST.js'
import JS from '../YapUI/YapUIJS.js'

// YapUI Rendering
import { Renderer , RendererContent, RendererRenderType } from '../YapUI/Renderer/Renderer.js'


import { ComposerInspector } from './ComposerInspector.js';

/**
 * Maps a Yap Designer component implemented with YapUI, to a Yap Content Builder component.
 */
export class YapUIComponent {
    constructor({ name,  path, metadata, yapui, thumbnail, inspector, viewCallback, assetCallback, defaultsCallback, runtime }) {
        this.type = name
        this.name = name
        this.metadata = metadata
        this.yapui = yapui
        this.thumbnail = thumbnail  
        this.inspector = inspector
        this.path = path
        this.viewCallback = viewCallback
        this.assetCallback = assetCallback
        this.defaultsCallback = defaultsCallback
        this.runtime = runtime
    }

    defaultProps() {
        return {
            
        };
    }

    // Titles used for validation
    titlesForProp() {
        return {
            
        };
    }

    // Element ids used for validation
    elementsForProp() {
        return {
           
        };
    }

    yapUI(props) {
        let componentProps = {...props}
        delete componentProps['id']

        return { content: JS.Directive(this.name, componentProps, []) }
    }

    decode(props) {
        let environment = {
            ...props
        }

        /** Decode inspector JSON */
        var inspector = {}
        try {
            if (this.inspector) {
                inspector = JSON.parse(this.inspector)  
            } else {
                console.log('no inspector')
            }
        } catch(e) { 
            //console.error('Error decoding inspector', e, this.inspector)    
        }

        return (<Section>
                    <View model={{...props}} inspector={ (model, updateModel) => {
                        return <ComposerInspector name={this.metadata.name ?? this.name} inspector={inspector} model={model} updateModel={updateModel}/>
                    }}>
                        <RendererContent 
                                runtime={this.runtime}  
                                componentName={this.name}
                                getAsset={this.assetCallback}
                                routeCallback={() => { }}
                                viewCallback={this.viewCallback} 
                                defaultsCallback={this.defaultsCallback}
                                jsCallback={() => { }}
                                frame={null} 
                                props={props}
                                environment={environment}
                                preferredType={RendererRenderType.body}    
                                allowDefaultFrame={false}/>
                    </View>
            </Section>
        );
    }

    // model is new, props is existing
    encode(model, props) {

        var newModel = {...props, ...model};
        delete newModel['sectionId'];

        // remove any values that are undefined or a function
        Object.keys(newModel).forEach(key => {
            if (newModel[key] === undefined || typeof newModel[key] === 'function') {
                delete newModel[key];
            }
        })

        console.log('newModel ', newModel)
        return newModel;
    }

    icon() {

        const environment = {
            'displaySize' : { width: 150, height: 70 },
            'preview'     : 'thumbnail'
        }

        return (
            <IconContainer>
                <Renderer runtime={this.runtime}
                            componentName={this.name}
                            getAsset={this.assetCallback}
                            routeCallback={() => { }}
                            viewCallback={this.viewCallback} 
                            jsCallback={() => { }}
                            frame={null} 
                            previews={[]}
                            previewIndex={null} 
                            preferredType={RendererRenderType.thumbnail}
                            allowDefaultFrame={false}
                            environment={environment}/>
            </IconContainer>
        )
    }
}

const IconContainer = styled.div`   
    width: 100px;
    height: 70px;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;    

    & .rendererContainer svg {
        border-radius: unset !important;
    }
`   

const ComponentContainer = styled.div`  
    display: contents;

    & > :first-child {  
        overflow: unset;
        height: auto;
    }
`
