import React, { useState, useEffect, useLayoutEffect, Suspense } from 'react'
import styled from 'styled-components'
import { Renderer, RendererRenderType } from './YapUI/Renderer/Renderer';
import pathutil from 'path-browserify';

export function ComponentGrid({ items, editor, groupByFolder = true }) {
    let environment = {
        'screen'  : 'mobile',
        'preview' : 'thumbnail',
        'displaySize' : { width: 150, height: 150 },
        'thumbnail.size.width' : 150,
        'thumbnail.size.height' : 150,
    }

    let basePath = editor.selectedPath

    var groups = [ { name: ".", items: [] } ]
    var groupPaths = { "." : groups[0] } 

    items.forEach((item) => {   
        let rootPath = item.path.replace(basePath, "")  
        let pathName = pathutil.dirname(rootPath)   
        let group = { items: []}

        if (groupPaths[pathName] == null) {
            group = { 
                name: pathName,
                items: []
            }
            groupPaths[pathName] = group    
            groups.push(group)
        } else {
            group = groupPaths[pathName]    
        }

        group.items.push(item)    
    })

    groups = groups.filter((group) => group.items.length > 0)

    var renderItems = items 
    var content = null

    if (groupByFolder) {
        content = (<ComponentGroup>
                        {groups.map((group, index) => {
                            let showHeader = group.name != "."

                            return <>
                                        {showHeader ? <ComponentGroupHeader index={index}>{group.name}</ComponentGroupHeader> : null}
                                        <ComponentGridContainer>
                                            {group.items.map(component => {    
                                                return <ComponentGridItem key={component.path} environment={environment} editor={editor} item={component} />
                                            })}
                                        </ComponentGridContainer>
                                </>
                        })}
                        </ComponentGroup>
                )

    } else {
        content = (
            <ComponentGridContainer>
                {renderItems.map((component, index) => {
                    return <ComponentGridItem key={component.path} environment={environment} editor={editor} item={component} />
                })}
            </ComponentGridContainer>
        );
    }

    return <ComponentGridContent>{content}</ComponentGridContent>

}

export function ComponentGridItem({ item, environment, editor }) {

    //let thumbnail = item.thumbnail    
    // if (item.thumbnailItem) {
    //     //console.log('Looking for thumbnail', item.thumbnailItem)
    //     let view = editor.findItem(item.thumbnailItem)
    //     //console.log(view)
    //     if (view && view.thumbnail) {
    //         thumbnail = view.thumbnail
    //     }
    // }

    const renderer = <Renderer  componentName={item.name}
                                preferredType={RendererRenderType.thumbnail}
                                runtime={editor.runtime}  

                                viewCallback={editor.viewCallback}
                                defaultsCallback={editor.defaultsCallback}  
                                getAsset={editor.getAsset}
                                jsCallback={editor.jsCallback}

                                frame={null}
                                environment={environment}
                                allowDefaultFrame={false}
                                previewIndex={0} />

        
    return (
        <ComponentGridItemContainer onClick={ () => {
            editor.setSelectedId(item.path)
        }}>
            <ComponentGridItemRender>{renderer}</ComponentGridItemRender>
            <span className="title">{item.metadata ? item.metadata.name : item.name}</span>
        </ComponentGridItemContainer>
    );
}

const ComponentGroup = styled.div`
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
`

const ComponentGridContent = styled.div`
    padding: 25px;
    background-color: #fcfcfc;    
    width: -webkit-fill-available;
`

const ComponentGroupHeader = styled.h2`
    
    grid-column: span 12;
    font-size: 14px;
    font-weight: 600;
    border-top: ${props => props.index > 0 ? "1px solid #e3e3e3" : "none"};
    margin-bottom: 25px;
    margin-top: ${props => props.index > 0 ? "50px" : "0px" };
    padding-top: ${props => props.index > 0 ? "20px" : "0px" };
`
const ComponentGridItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    justify-content: space-between;
    align-items: center;

    gap: 10px;
    cursor: pointer;

    .title {
        font-size: 13px;
        justify-self: flex-end;
        text-align: center;
        padding-left: 8px;
        padding-right: 8px;
        line-height: 18px;
    }
`

const ComponentGridItemRender = styled.div`
    height: 200px;
    width: 200px;
    padding: 0px;
    box-sizing: border-box;
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid #e3e3e3;  
    background-color: white;
`

const ComponentGridContainer = styled.div`
    display: grid;
    //overflow-x: scroll;
    // width: -webkit-fill-available;
    // height: -webkit-fill-available;
    grid-template-columns: repeat(auto-fit, 200px); /* Responsive columns */
    ggrid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 240px;
    grid-gap: 20px;
    // padding-left: 20px;
    // padding-right: 20px;
    justify-items: flex-start; /* Align items to the start of each grid cell */
    justify-content: flex-start; /* Align the entire grid to the left */
    align-items: flex-start; 
    // padding-top: 25px;
`;