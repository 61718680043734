import React from 'react';
import { useAppState } from '../../content-builder-components/ContentBuilderStateProvider.js';
import AssetImage from "../../asset-components/AssetImage.js";
import PolymerAssetType from '../../asset-components/AssetType.js';


function InspectorImageWell(props) {
    if (props.children) {
        return <InspectorImageWellSelector {...props}/>
    } else if (props.condensed == true) {
        return <InspectorImageWellCondensed {...props}/>
    } else {
        return <InspectorImageWellLarge {...props}/>
    }
}

function InspectorImageWellSelector(props) {
    const { setShowAssetLibrary, onAssetSelected, setAssetLibraryTypes } = useAppState();

    const onImageSelectedCallback = (asset) => {
        if (props.onImageSelected) {
            props.onImageSelected(asset);
        }
    };

    return (<div
            onClick={() => {
                setAssetLibraryTypes([PolymerAssetType.Image]);
                setShowAssetLibrary(true);
                onAssetSelected.current = onImageSelectedCallback;
            }}
            >
            {props.children}
        </div>
    )

}

function InspectorImageWellLarge(props) {
    const { setShowAssetLibrary, onAssetSelected, setAssetLibraryTypes } = useAppState();

    const [dimensions, setDimensions] = React.useState(null);
    var content = "Choose Image";
    var style = {};

    if (dimensions) {
        style = {
            backgroundSize: 'cover',
            aspectRatio: dimensions.width / dimensions.height,
            width: '100%',
            height: 'unset'
        };
    }

    const onImageSelectedCallback = (asset) => {
        if (props.onImageSelected) {
            props.onImageSelected(asset);
        }
    };

    const chooseTitle = (
        <div key={"title"} className="inspector-image-well-title">
            <span>{content}</span>
        </div>
    );

    const replaceTitle = (
        <div key={"replaceTitle"} className="inspector-image-well-action">
            <span>{"Replace"}</span>
        </div>
    );
    
    const hasImage = props.src != null
    const imageWellClassName = hasImage ? "image-filled" : ""

    return (
        <div className="inspector-property" style={{ paddingBottom: "0px" }}
            onClick={() => {
                setAssetLibraryTypes([PolymerAssetType.Image]);
                setShowAssetLibrary(true);
                onAssetSelected.current = onImageSelectedCallback;
            }}>

            <div className={`inspector-image-well ${imageWellClassName}`} style={style}>
                {props.src != null && <AssetImage key={"asset"} asset={props.model} onDimensions={setDimensions} width={"100%"} maxHeight={300}/>}
                {props.src == null && chooseTitle}
                {props.src != null && replaceTitle}
            </div>
        </div>
    );
}

function InspectorImageWellCondensed(props) {
    const appState = useAppState();

    const setShowAssetLibrary = appState?.setShowAssetLibrary;
    const onAssetSelected = appState?.onAssetSelected;   
    const onAssetDelete = appState?.onAssetDelete;   
    const setAssetLibraryTypes = appState?.setAssetLibraryTypes;

    //{ setShowAssetLibrary, onAssetSelected, onAssetDelete, setAssetLibraryTypes }

    const [dimensions, setDimensions] = React.useState(null);
    var content = "Choose Image";
    var style = {};

    const onImageSelectedCallback = (asset) => {
        if (props.onImageSelected) {
            props.onImageSelected(asset);
        }
    };

    const chooseTitle = (
        <div key={"chooseTitle"} className="inspector-image-well-title">
            <span>{content}</span>
        </div>
    );

    const fileTitle = (
        <div key={"fileTitle"} className="inspector-image-well-title">
            <span>{props.model.title}</span>
        </div>
    );

    const removeAction = <div className="remove" onClick={(e) => {
        if (props.onImageClear) {
            props.onImageClear();
        }
        if (e.preventDefault) {
            e.preventDefault()
        }

        if (e.stopPropagation) {
            e.stopPropagation()
        }        
        return false
    }}>                   
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.25" d="M8.36719 16.3281C4.01562 16.3281 0.398438 12.7109 0.398438 8.35938C0.398438 4 4.00781 0.390625 8.35938 0.390625C12.7188 0.390625 16.3359 4 16.3359 8.35938C16.3359 12.7109 12.7266 16.3281 8.36719 16.3281ZM5.74219 11.6406C5.92188 11.6406 6.08594 11.5781 6.21094 11.4531L8.36719 9.28125L10.5312 11.4531C10.6484 11.5781 10.8125 11.6406 10.9922 11.6406C11.3594 11.6406 11.6484 11.3516 11.6484 10.9922C11.6484 10.8125 11.5859 10.6484 11.4531 10.5312L9.28906 8.36719L11.4609 6.19531C11.6016 6.05469 11.6562 5.91406 11.6562 5.73438C11.6562 5.375 11.3672 5.09375 11.0078 5.09375C10.8359 5.09375 10.6953 5.14844 10.5625 5.28125L8.36719 7.46094L6.1875 5.28906C6.0625 5.17188 5.92188 5.10938 5.74219 5.10938C5.38281 5.10938 5.10156 5.38281 5.10156 5.75C5.10156 5.92188 5.16406 6.07812 5.28906 6.20312L7.45312 8.36719L5.28906 10.5391C5.16406 10.6562 5.10156 10.8203 5.10156 10.9922C5.10156 11.3516 5.38281 11.6406 5.74219 11.6406Z" fill="black"/>
        </svg>
    </div>


    return (
        <div className="inspector-property" style={{ paddingBottom: "0px", paddingTop: "0px" }}
            onClick={() => {
                if (setAssetLibraryTypes) {
                    setAssetLibraryTypes([PolymerAssetType.Image]);
                }
                if (setShowAssetLibrary) {
                    setShowAssetLibrary(true);
                }
                if (onAssetSelected) {  
                    onAssetSelected.current = onImageSelectedCallback;
                }
            }}>

            <div className="inspector-image-well-condensed image-filled" style={style}>
                <div key={"thumbnail"} className="thumbnail">
                    {props.src != null && <AssetImage asset={props.model} onDimensions={setDimensions} width={"100%"} height={"100%"} />}
                </div>
                <span>
                    {props.src == null && chooseTitle}
                    {props.model.title != null && fileTitle}
                </span>
                {props.src != null && props.onImageClear && removeAction}
            </div>
        </div>
    );
}

export { InspectorImageWell, InspectorImageWellCondensed }