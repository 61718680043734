import React, { useState, useEffect } from 'react';
import { useForegroundStyle, useStyle } from '../Style.tsx';
import { useActions } from '../Actions.tsx';
import { SFSymbol } from './Utils/SFSymbol.tsx';
import { useAssets, AssetImageType, getAssetSetBaseImage, getAssetSetRetinaImage } from '../Assets.tsx';
import { px } from '../../Utils.tsx';
// Define a type for the Asset object

export interface UIImage {
    value?: string;
    named?: string;
    url?: string;
    systemName?: string;
    crop?: { x: number, y: number, width: number, height: number };
    dimensions?: { width: number, height: number };
}

export function UIImage({ value, named, url, crop, dimensions, systemName }: UIImage) {
    const style = useStyle();
    const foregroundStyle = useForegroundStyle();
    const actions = useActions();
    const assets = useAssets();

    let assetName = named ?? value;
    if (assetName && assets.get) {
        let assetSet = assets.get(assetName);
        let asset : AssetImageType | null = assetSet ? getAssetSetBaseImage(assetSet) : null;

        if (asset) {
            let dimensions = asset.dimensions ?? { width: undefined, height: undefined };
            let retinaAsset = getAssetSetRetinaImage(assetSet) ?? asset;
            let url = retinaAsset.url 
            
            /** div is here to allow :after, :before css selectors used in composer */
            return (
                <div style={{ width: px(dimensions.width), height: px(dimensions.height), ...style }}>
                    <img style={style} {...actions} width={dimensions.width} height={dimensions.height} src={url} alt={asset.alt} />
                </div>
            );
        }
    } else if (systemName) {
        
        const fontSize = style.fontSize ? parseFloat(String(style.fontSize)) : 17
        const fill = foregroundStyle.color ? foregroundStyle.color : 'black'

        return (
            <div style={style}>
                <SFSymbol name={systemName} size={fontSize} color={fill}/>
            </div>
        );

    } else if (url) {
        let background = {
            backgroundImage: `url(${url})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            ...style
        }
        return (
            <div style={background}>
                {/* <img style={style} {...actions} src={url} alt="placeholder" /> */}
            </div>
        );
    }

    return null;
}
