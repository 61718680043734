import * as React from 'react'
import { useIcons } from "../content-builder-components/ContentBuilderIconProvider.js"

export default function SegmentedControl({ titles, options, keys, onChange, value, selectedIndex, setSelectedIndex }) {
    
    let icons = useIcons()

    const items = (titles ?? options).map( (item, itemIdx) => {

        var idx   = item.value ?? itemIdx

        const handlePress = (e) => {
            let keyCode = e.keyCode ? e.keyCode : e.which;
    
            // Enter or space key
            if (keyCode === 13 || keyCode == 32) {
                setSelectedIndex(idx)
                e.preventDefault()
            }
        }
    
        var selected      = false
        
        if (keys) {
            selected = value == keys[idx]
        } else {
            selected = selectedIndex == idx
        }
        const selectedClass = (selected ? "selected" : "");

        var title = item
        if (item.icon) {
            title = icons.get(item.icon, selected ? 'color-tint' : 'color-value')
        } else if (item.title) {
            title = item.title
        }

        return (
            <div key={String(idx)} 
                 tabIndex={0} 
                 role={"tab"} 
                 aria-selected={selected} 
                 className={`polymer-segmented-control-item noselect ${selectedClass} ${idx}`} 
                 onKeyUp={handlePress}
                 onClick={ () => {
                    if (onChange) {
                        onChange(keys[idx]);
                    } else if (setSelectedIndex) {
                        setSelectedIndex(idx)
                    }
            }}>
                {title}
            </div>
        )

    })

    return <div role={"tablist"} className="polymer-segmented-control">{items}</div>
}