import React, { createContext, useContext, ReactNode } from 'react';
import { useColorNode } from './Color.tsx';
import { useStyle } from '../Style.tsx';

function useLinearGradient({ rawValue }) {
  const { colors, locations } = rawValue;
  const linearGradient = colors.map((color, index) => {
    const location = locations[index] || (index / colors.length) * 100;
    return `${color} ${location}%`;
  }).join(', ');

  return linearGradient;
}

export function LinearGradient({ colors, startPoint, endPoint, children, content }) {

    let colorOne = useColorNode(colors[0])
    let colorTwo = useColorNode(colors[1])  
    
    let degrees = angleFromPoints(startPoint, endPoint)

    let css = 'linear-gradient(' + String(degrees) + 'deg, ' + colorOne + ', ' + colorTwo + ')'
    
    const currentStyle = useStyle()
    const style = {
        ...currentStyle,
        background: css,
    };

    return <div style={style}/>
} 

function angleFromPoints(startPoint = 'bottom', endPoint = 'top') {
    switch (`${startPoint}-${endPoint}`) {
        case "top-bottom":
            return 180;
        case "bottom-top":
            return 0;
        case "leading-trailing":
            return 90;
        case "trailing-leading":
            return 270;
        case "topLeading-bottomTrailing":
            return 135;
        case "bottomTrailing-topLeading":
            return 315;
        case "topTrailing-bottomLeading":
            return 45;
        case "bottomLeading-topTrailing":
            return 225;
        default:
            return 0; // Return null for invalid point combinations
    }
}

export function LinearGradientContent() {

}