import React, { useEffect, createContext, useContext } from 'react';

// Styling
import styled from 'styled-components'

// Define a type for the style context
export interface PathContextType {
    path: string | null;
    detailPath: string | null;
    root: string | null;
}

// Create the StyleContext with an empty default value
export const PathContext = createContext<PathContextType>({ path: null, detailPath : null, root: null });   


// Custom hook to use the StyleContext
export function usePathContext() {
    return useContext(PathContext);
}


export function PathBar({ onChange }) {    
    const context = usePathContext()
    if (context == null) { return "no path" }
    const path = context.path;
    if (path == null)  { return " no path"; }

    let comps = path.split("/");
    if (path.endsWith("/")) {
        comps.pop();
    }

    let caret = (
        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.3" d="M8.68359 8.76953C8.68359 8.91406 8.6543 9.04883 8.5957 9.17383C8.54102 9.29492 8.45312 9.41406 8.33203 9.53125L3.9082 13.8672C3.72852 14.0469 3.50977 14.1367 3.25195 14.1367C3.08398 14.1367 2.92773 14.0938 2.7832 14.0078C2.63867 13.9258 2.52344 13.8145 2.4375 13.6738C2.35547 13.5332 2.31445 13.377 2.31445 13.2051C2.31445 12.9473 2.41406 12.7168 2.61328 12.5137L6.47461 8.76367L2.61328 5.01953C2.41406 4.82422 2.31445 4.5957 2.31445 4.33398C2.31445 4.16211 2.35547 4.00586 2.4375 3.86523C2.52344 3.72461 2.63867 3.61328 2.7832 3.53125C2.92773 3.44531 3.08398 3.40234 3.25195 3.40234C3.50977 3.40234 3.72852 3.49023 3.9082 3.66602L8.33203 8.00195C8.45312 8.11914 8.54102 8.24023 8.5957 8.36523C8.65039 8.48633 8.67969 8.62109 8.68359 8.76953Z" fill="black"/>
        </svg>
    )

    const rootElement = (
        <>
        <PathBarItem onClick={() => { onChange("") }}>
                {context.root}
        </PathBarItem>
        {comps.length > 0 && path.length > 0 && caret}
        </>                        
    )

    return (
        <PathBarContainer className="path-bar">
            {rootElement}            
            {comps.map((item, index) => {
                return <>
                    <PathBarItem onClick={() => {
                        let newPath = comps.slice(0, index + 1).join("/")
                        if (index == comps.length - 1) {
                            onChange(newPath)
                        } else {
                            onChange(newPath)
                        }
                    }} key={index}>
                        {item}
                    </PathBarItem>
                    {index != comps.length - 1 && caret}
                </>
            })}
        </PathBarContainer>
    );
}

const PathBarContainer = styled.div`
    display: flex;  
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
`

const PathBarItem = styled.div` 
    cursor: pointer;    
`
