import { initializeApp } from 'firebase/app';
import { getFirestore, collection, doc, setDoc, getDoc, query, deleteDoc, getDocs, addDoc,onSnapshot } from "firebase/firestore";
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL, } from "firebase/storage";

/*
 * --------------
 * Firebase Setup
 * --------------
 */
// TODO: Replace the following with your app's Firebase project configuration
// Your web app's Firebase configuration
export const firebaseConfig = {
    apiKey: "AIzaSyBhOSQVlVhXXQlLHu_PMVmHcpWsVfDc3uI",
    authDomain: "content-builder-aa1f5.firebaseapp.com",
    projectId: "content-builder-aa1f5",
    storageBucket: "content-builder-aa1f5.appspot.com",
    messagingSenderId: "408747115076",
    appId: "1:408747115076:ios:1fd609aa238f40a9abf211"
};

export const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);