import * as React from 'react'

function containsKey(dictionary, keysArray) {
    // First, check if the dictionary is a valid object
    if (typeof dictionary !== 'object' || dictionary === null || Array.isArray(dictionary)) {
        return false;
    }

    // Iterate through the array of keys
    for (let key of keysArray) {
        // Check if the dictionary contains the current key
        if (dictionary.hasOwnProperty(key)) {
            return true;
        }
    }
    // If none of the keys were found, return false
    return false;
}

const variableRegex = /^[\s\r\n]*\$/;
export function isVariable(value) {
    return typeof value === 'string' && variableRegex.test(value);
}

const variableExtractRegex = /^[\s\r\n]*\$(.*?)[\s\r\n]*$/;
export function extractVariable(value) {
    return value ? value.replace(variableExtractRegex, '$1') : null;
}

class YapUIGenerator {
    constructor({ nameCallback, viewCallback }) {
        this.nameCallback = nameCallback;
        this.viewCallback = viewCallback;

        this.modifiers = [
            'padding',
            'blur',
            'background',
            'border',
            'cornerRadius',
            'containerRelativeFrame',
            'frame',
            'font',
            'lineSpacing',
            'foregroundStyle',
            'flexFrame',
            'safeAreaPadding',
            'id'
        ];
        
        this.containsTextContent = [
            'Text', 'Button', 'UIFunction', 'Function', 'Paragraph'
        ]

        this.propRemap = {
            'maxwidth' : 'maxWidth',
            'maxheight' : 'maxHeight',
            'idealwidth' : 'idealWidth',
            'idealheight' : 'idealHeight'
        }

        this.modifierValueMap = {
            'padding': 'length',
            'containerrelativeframe' : 'axes'
        }
    }

    containsNonWhitespace(str) {
        return /\S/.test(String(str));
    }

    encode(node, hasTextContent = false) {
        // Base case: if the node is not a valid React element, return null
        if (!React.isValidElement(node)) {
            if (hasTextContent) {
                return node;
            } else {
                return null;
            }
        }

        // Get the type (component or HTML tag) and props
        const { type } = node;
        var props = {...node.props };
        
        // Prepare the node object
        let name = typeof type === 'string' ? type : type.displayName || type.name || type.displayName || 'Unknown'

        var args = {}
        var children = []

        for (const modifierName of this.modifiers) {
            if (modifierName.toLowerCase() == name.toLowerCase()) {
                name = modifierName
            }

            switch (name) {
                // case 'frame':
                //     if (containsKey(props, ['maxwidth', 'maxheight', 'idealwidth', 'minwidth', 'minheight', 'idealheight'])) {
                //         name = 'flexFrame'
                //     } else {
                //         name = 'frame'
                //     }
                //     break;
                case 'Variable':
                    args['key'] = props['name'];
                    break;
            }
        }

        if (this.nameCallback) {
            let newName = this.nameCallback(name.toLowerCase())
            if (newName) {
                name = newName;
            }
        }

        // Add props to the JSON tree, excluding children
        for (const propName in props) {
            var propValue = props[propName];
            
            // Conver variable
            if (isVariable(propValue)) {
                propValue = Variable(extractVariable(propValue));
            }

            if (propName !== 'children' && !this.modifiers.includes(propName)) {
                var newPropName = this.propRemap[propName] ?? propName
                args[newPropName] = propValue;
            }

            if (propName == 'value' && this.modifierValueMap[name.toLowerCase()] && this.modifiers.includes(name)) {
                var newPropName = this.modifierValueMap[name.toLowerCase()] ?? 'value'
                args[newPropName] = propValue;   
                delete args['value']
            }
        }

        const hasAnyReactNodes = props.children && React.Children.toArray(props.children).filter( (item) => { React.isValidElement(item) }) > 0;

        // Recursively traverse the children if they exist
        React.Children.forEach(props.children, (child) => {
            if (isVariable(child)) {
                children.push(Variable(extractVariable(child)));
            } else {
                const childNode = this.encode(child, hasAnyReactNodes == false && this.containsTextContent.includes(name));
                if (childNode) {
                    children.push(childNode);
                }
            }
        });


        var jsonNode 
        switch (name) {
            case 'Variable':
                jsonNode = Variable(args['name']);
                break;
            default:
                jsonNode = Directive(name, args, children);
                break;
        }
        
        // var finaljsonNode = jsonNode
        // for (const propName in props) {
        //     if (this.modifiers.includes(propName)) {
        //         finaljsonNode = Directive(propName, { 'value': props[propName] }, [finaljsonNode]);
        //     }
        // }
        return jsonNode;
    }

}

function Directive(name, args, children) {
    return {
        'type': name,
        'props': args ?? {},
        'children': children ?? []
    }
}

function Modifier(name, args, children) {
    return ModifiedContent(Directive(name, args, children), children)
}

function ModifiedContent(modifier, component)  {
    var mod = {...modifier}
    mod.children = [component]
    return mod
}

function Variable(key) {
    return {
        'type' : 'Variable',
        'key' : key
    }
}

function Arg(name, type) {
    return {
        "data": name,
        "_type": 'raw'
    }
}

function Val(value) {
    return value
}

function String(value) {
    return Val(value, "String")
}

function Double(value) {
    return Val(value, "Double")
}

let YapUI = { Directive, Val, Double, String, ModifiedContent, Variable }

export { YapUIGenerator, YapUI }