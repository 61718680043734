import React from 'react'

const PolymerPageStateContext = React.createContext()

function useYapPageState() {
    const context = React.useContext(PolymerPageStateContext)
    return context
}

function Page(props) {
    const [headerTitle, setHeaderTitle] = React.useState(null)
    const [headerActions, setHeaderActions] = React.useState(null)
    const [headerActionsLeft, setHeaderActionsLeft] = React.useState(null)
    const [backAction, setBackAction] = React.useState(null)
    const [doneAction, setDoneAction] = React.useState(null)
    const [prefersFullscreen, setPrefersFullscreen] = React.useState(null)
    const [prefersLargeTitle, setPrefersLargeTitle] = React.useState(true)
    const [prefersNoContainer, setPrefersNoContainer] = React.useState(false)
    const [hasFixedHeader, setHasFixedHeader] = React.useState(false)
    const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false)
    const [dropdownId, setDropdownId] = React.useState(null)
    const [isReadonly, setReadonly] = React.useState(false)

    const clearDropdownRef = React.useRef(null)
    let clearDropdownId = () => {
        clearTimeout(clearDropdownRef.current)
        clearDropdownRef.current = setTimeout( () => {
            setDropdownId(null)
        }, 200)
    }

    let contextValue = {
        setHeaderTitle: setHeaderTitle,
        headerTitle: headerTitle,
        setHeaderActions: setHeaderActions,
        headerActions: headerActions,

        setHeaderActionsLeft: setHeaderActionsLeft,
        headerActionsLeft: headerActionsLeft,

        setBackAction: setBackAction, 

        backAction: backAction,
        onBack: props.onBack,
        
        setDoneAction: setDoneAction,
        doneAction: doneAction,

        setPrefersFullscreen: setPrefersFullscreen,
        prefersFullscreen: prefersFullscreen,

        setPrefersNoContainer: setPrefersNoContainer,
        prefersNoContainer: prefersNoContainer,

        setPrefersLargeTitle: setPrefersLargeTitle,
        prefersLargeTitle: prefersLargeTitle,

        setHasFixedHeader : setHasFixedHeader,
        hasFixedHeader: hasFixedHeader,

        setHasUnsavedChanges : setHasUnsavedChanges,
        hasUnsavedChanges: hasUnsavedChanges,

        setReadonly: setReadonly,
        readonly: isReadonly,
        
        setDropdownId: (id) => {
            clearTimeout(clearDropdownRef.current)
            setDropdownId(id)
        },
        clearDropdownId: clearDropdownId,
        dropdownId: dropdownId
    }

    var fullscreen = false
    if (prefersFullscreen != null) { fullscreen = prefersFullscreen    }    
    if (props.fullscreen != null) { fullscreen = props.fullscreen }
    
    let headerClass = hasFixedHeader ? 'with-fixed-header' : ''

    var defaultInsetClass = props.defaultContainerClassName ?? "content-builder-container-inset"
    var defaultContainerClass = fullscreen ? "content-builder-container-fullscreen" : defaultInsetClass
    
    contextValue.containerClassName =  props.containerClassName ?? defaultContainerClass
    contextValue.canvasContainerClassName = props.canvasContainerClassName ? props.canvasContainerClassName : "content-builder-container-fullscreen";

    if (props.containerClassName !== undefined && props.containerClassName == null) {
        contextValue.containerClassName = null
    }

    let fullscreenClass = fullscreen ? "polymer-page-content-fullscreen" : "polymer-page-content-inset"
    let className = `polymer-page polymer-page-content ${fullscreenClass} ${headerClass}`
    if (props.disableClass) {
        className = null
    }
    return <PolymerPageStateContext.Provider value={contextValue}><div className={className}>{props.children}</div></PolymerPageStateContext.Provider>
}

function PageContent({ children, className }) {
    return <div className={`polymer-content ${className}`}>{children}</div>
}

export { Page, PageContent, useYapPageState } 