import * as React from 'react';
import Props from "../PropsHelper.js";
import AssetImage from '../../asset-components/AssetImage.js';
import { View } from './View.js';

function Image(props) {

    const containerWidth = props.containerWidth ?? 350
    const [dimensions, setDimensions] = React.useState(props.dimensions)
    const [size, setSize] = React.useState({ width: props.width ?? '100%', height: containerWidth})

    //
    // Update size based on aspect
    // 
    // aspect (optional)
    //      - original
    //      - 1 (square)
    //
    // If aspect is not passed in, then the height will be set to the height prop or container width.
    //
    React.useEffect( () => {
        // Original Aspect
        if (props.aspect == 'original' && dimensions != null) {
            setSize({ width: size.width, height: (containerWidth * (dimensions.height / dimensions.width)) })

        // Square Aspect
        } else if (props.aspect == '1') {
            setSize({ width: size.width, height: containerWidth })

        // Otherwise use height from props or container width
        } else {
            setSize({ width: size.width, height: props.height ?? containerWidth })
        }
    }, [dimensions, props.aspect])

    // 
    // onDimensions callback
    //
    const onDimensions = (dimensions) => {
        setDimensions(dimensions)
        if (props.onDimensions) {
            props.onDimensions(dimensions)
        }
    }

    var model = {
        type: 'image',
        onChange: props.onChange,
        ...props,
    };

    // Calc style
    var containerStyle = {};

    if (props.placeholder) {
        containerStyle['backgroundColor'] = '#696969';
    }

    if (props.shadow) {
        containerStyle['boxShadow'] = '0px 5px 60px rgba(0,0,0,0.3)';
    }

    containerStyle['borderRadius'] = Props.cornerRadius(props)
    containerStyle['transform'] = Props.transform(props)

    // Empty Image
    if (props.placeholder && model.src == null) {
        containerStyle.width = size.width;
        containerStyle.height = size.height;
        const placeholderStyle = {
            ...containerStyle,
            ...props.placeholderStyle,
            display: 'block'
        };

        if (props.readonly) {
            return <div style={placeholderStyle}/>
        } else {
            return <View {...props} model={model} ><div style={placeholderStyle}></div></View>;
        }

    // Image
    } else {

        const asset = { src: model.src, description: model.description, contrast: model.contrast, saturation: model.saturation, crop: model.crop };
        const assetImage = <AssetImage 
                                className="view-image" 
                                onDimensions={onDimensions} 
                                borderRadius={props.cornerRadius} 
                                asset={asset} 
                                width={size.width} 
                                height={size.height} />

        if (props.readonly) {
            return assetImage
        } else {
            return (
                <View {...props} model={model} style={containerStyle}>
                    {assetImage}
                </View>
            );
        }
    }
}

export { Image }