import { Navigate } from "react-router-dom";
import { useAuth } from "./Auth.js";

export const ProtectedRoute = ({ children }) => {
    const { currentUser } = useAuth()

    if (!currentUser) {
        console.log('No User')
        // user is not authenticated
        return <Navigate to="/logind" />;
    }
    return children;
};