import React, { createContext, ReactElement, useContext } from 'react';

// Define a type for the Asset object
export interface AssetImageType {
    id: string;
    alt: string;
    name: string;
    url: string;
    dimensions?: { width: number, height: number }; 
}


export interface AssetColorType {
    r: number;
    g: number;
    b: number;
    a: number;
    name: string;
}

// Define a type for the Asset object
export interface AssetSetType {
    assets: AssetImageType[] | AssetColorType[];
}

// Define a type for the style context
export interface AssetsContextType {
    get: (key: string, componentName: string, names: string[]) => AssetSetType | null;
    name: string;
    parentNames: string[];
}

// Create the AssetsContext with a default value
export const AssetsContext = createContext<AssetsContextType>({
    get: (key: string, componentName: string, names: string[]): AssetSetType | null => null,
    name: '',
    parentNames: []
});



// Provider component to pass down styles
export function AssetsProvider({ children, name, get }: { children: ReactElement[]; name: string, get: (key: string, componentName: string, names: string[]) => AssetSetType | null }) {
    let context = useContext(AssetsContext);    
    let parentNames : string[] = []
    if (context && context.parentNames) {
        parentNames = [...context.parentNames]
    }
    if (parentNames.indexOf(name) == -1) {
        parentNames.push(name)  
    }
    
    return <AssetsContext.Provider value={{ get: get, name: name, parentNames: parentNames }}>{children}</AssetsContext.Provider>;
}


// Custom hook to use the AssetsContext
export function useAssets() {
    let context = useContext(AssetsContext);
    return { get: (key: string) => {
        if (context.get) {
            return context.get(key, context.name, context.parentNames   );
        } else {
            return null
        }
    }}
}

export function getAssetSetBaseImage(assetSet: AssetSetType | null): AssetImageType | null {
    if (assetSet == null) { return null; }
    return (assetSet.assets.find((asset) => asset.name.indexOf('@') == -1) ?? null) as AssetImageType;
}

export function getAssetSetRetinaImage(assetSet: AssetSetType | null): AssetImageType | null {
    if (assetSet == null) { return null; }
    let twox = assetSet.assets.find((asset) => asset.name.endsWith('@2x')) ?? null;
    let threex = assetSet.assets.find((asset) => asset.name.endsWith('@3x')) ?? null;
    return (threex ?? twox) as AssetImageType;
}

export function getAssetColor(assetSet: AssetSetType | null): AssetColorType | null {
    let first = assetSet?.assets[0]
    if (first) {
        return first as AssetColorType
    } else {
        return null
    }
}