import { useEffect } from "react";
import { tinykeys } from "tinykeys"

const useKeyCombo = (keyMap) => {
  useEffect(() => {
    const enhancedKeyMap = Object.entries(keyMap).reduce((acc, [keyCombo, handler]) => {
        // Wrap the handler with preventDefault() to block the default browser action
        acc[keyCombo] = (event) => {
          event.preventDefault();
          handler(event);
        };
        return acc;
      }, {});

    const unsubscribe = tinykeys(window, enhancedKeyMap);

    // Cleanup: Unsubscribe from the key listener on unmount
    return () => {
      unsubscribe();
    };
  }, [keyMap]);
};

export default useKeyCombo;

// const useKeyCombo = (keyCombo, callback) => {
//   useEffect(() => {
//     const handleKeyDown = (event) => {
//       // Check if all required modifier keys are pressed
//       const isCorrectCombo =
//         (!keyCombo.ctrlKey || event.ctrlKey) &&
//         (!keyCombo.metaKey || event.metaKey) &&
//         (!keyCombo.shiftKey || event.shiftKey) &&
//         (!keyCombo.altKey || event.altKey) &&
//         event.key === keyCombo.key;

//       if (isCorrectCombo) {
//         event.preventDefault();
//         callback(); // Call the passed callback function
//       }
//     };

//     // Add event listener on component mount
//     window.addEventListener("keydown", handleKeyDown);

//     // Remove event listener on component unmount
//     return () => {
//       window.removeEventListener("keydown", handleKeyDown);
//     };
//   }, [keyCombo, callback]);
// };

// export default useKeyCombo;