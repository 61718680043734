import React, { useEffect, useRef } from 'react';
import { YapJSRuntime } from "./JSRuntime"
import { componentsMap, modifiersMap } from './YapUIDecoder';
export function useJSRuntime(id) {
    const ref = useRef(new YapJSRuntime())
    const lastIdRef = useRef(id)
    const runtime = ref.current
    let reservedComponents = ['Self', 'ComposerAdd']

    if (lastIdRef.current !== id) {
        console.log('reset storage')
        ref.current.resetStorage()
        lastIdRef.current = id
    }   

    runtime.registerASTComponents(reservedComponents)    

    return runtime
}