import React, { ReactNode, useContext, createContext, ReactElement } from 'react';
import { useVariables } from './Variable.tsx';
import { useStyle, StyleProvider } from './Style.tsx';
import { useFunctions } from './Function.tsx';

export function OnTap({ value, children } : { value?: string, children: React.ReactNode[] }) {
    var actions = {...useActions()};
    var style = { ...useStyle() }
    var functionData = useFunctions()

    var { setVariables, variables } = useVariables()
    
    actions['onClick'] = () => {
        if (value == null) { return }

        // todo
    }

    style['cursor'] = style['cursor'] ?? 'pointer';

    return <StyleProvider style={style}><ActionsProvider actions={actions}>{children}</ActionsProvider></StyleProvider>
}

export function ClearActions({ children }) {
    return <ActionsContext.Provider value={{actions: {}}}>{children}</ActionsContext.Provider>
}


// Create the StyleContext with an empty default value
export const ActionsContext = createContext<ActionsContextType>({ actions: {} });

// Define a type for the style context
export interface ActionsContextType {
    actions: React.HTMLAttributes<HTMLElement>;
}

export function useActions() : React.HTMLAttributes<HTMLElement> {
    return useContext(ActionsContext).actions ?? { };
}

// Provider component to pass down styles
export function ActionsProvider({ children, actions }: { children: ReactNode; actions: React.HTMLAttributes<HTMLDivElement> }) {
    return <ActionsContext.Provider value={{ actions }}>{children}</ActionsContext.Provider>;
}
