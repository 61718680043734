import React from 'react'

import { useAppState } from '../content-builder-components/ContentBuilderStateProvider.js'
import { useInspectorState, InspectorStateProvider } from './InspectorState.js'

import { InspectorImageWell } from "./inspector-ui/InspectorImageWell.js"
import { InspectorImageActions } from "./inspector-ui/InspectorImageActions.js"
import { InspectorListItems } from './inspector-ui/InspectorListItems.js'
import { InspectorControlDate } from './inspector-ui/InspectorControlDate.js'
import { InspectorControlButton } from "./inspector-ui/InspectorControlButton.js"
import { InspectorControlText } from "./inspector-ui/InspectorControlText.js"
import { InspectorControlSelect } from "./inspector-ui/InspectorControlSelect.js"
import { InspectorControlSegmented } from "./inspector-ui/InspectorControlSegmented.js"
import { InspectorProperty } from "./inspector-ui/InspectorProperty.js"
import { InspectorHeader } from "./inspector-ui/InspectorHeader.js"

import { InspectorImage } from './inspectors/InspectorImage.js'
import { InspectorSpacer } from './inspectors/InspectorSpacer.js'
import { InspectorText } from './inspectors/InspectorText.js'
import { InspectorDate } from './inspectors/InspectorDate.js'
import { InspectorVideo } from './inspectors/InspectorVideo.js'
import { InspectorInfo } from './inspectors/InspectorInfo.js'
import { InspectorActionButton } from './inspectors/InspectorActionButton.js'
import { InspectorPage } from './inspectors/InspectorPage.js'

function Inspector(props) {
    let { sectionId, updateModel } = useAppState()

    var id = props.selectedId
    var model = props.model
    if (!model) {
        return null
    }

    var onClick = function (e) {
        e.stopPropagation()
    }

    // If content for inspector is passed used that, else base it on type (legacy)
    var content = props.content ? props.content(model ?? {}, updateModel) : null;

    switch (model.type) {
        case 'text':
            content = <InspectorText model={model} />;
            break;
        case 'image':
            content = <InspectorImage model={model} />;
            break;
        case 'page':
            content = <InspectorPage model={model} />;
            break;
        case 'spacer':
            content = <InspectorSpacer model={model} />;
            break;
        case 'quiz':
            content = <InspectorQuiz model={model} />;
            break;
        case 'video':
            content = <InspectorVideo model={model} />;
            break;
        case 'action':
            content = <InspectorActionButton model={model} />;
            break;
        case 'info':
            content = <InspectorInfo model={model} />;
            break
        case 'date':
            content = <InspectorDate model={model} />;
            break;
        default:
            break;
    }

    if (content == null) {
        return null
    }

    return (
        <InspectorStateProvider model={model} updateModel={updateModel}>
            <div className="inspector" onClick={onClick}>
                <div className="inspector-content">
                    {content}
                </div>
            </div>
        </InspectorStateProvider>
    )
}

function InspectorContent(props) {
    return (
        <div>
            {props.children}
        </div>
    )
}

export {
    Inspector, 
    InspectorProperty,
    InspectorHeader,
    InspectorControlSegmented,
    InspectorControlDate,
    InspectorControlText,
    InspectorControlSelect,
    InspectorContent,
    InspectorImageWell, 
    InspectorImageActions,
    InspectorControlButton,
    InspectorListItems,
    useInspectorState,
    InspectorStateProvider
}