export const componentNames = [
    "AngularGradient",
    "AnyView",
    "AsyncImage",
    "Body",
    "Button",
    "Canvas",
    "Circle",
    "Color",
    "ColorPicker",
    "ContentUnavailableView",
    "ControlGroup",
    "DatePicker",
    "DisclosureGroup",
    "Divider",
    "EditButton",
    "EllipticalGradient",
    "Ellipse",
    "EmptyModifier",
    "EmptyView",
    "EquatableView",
    "ForEach",
    "Form",
    "Gauge",
    "GeometryReader",
    "GeometryReader3D",
    "Grid",
    "GridRow",
    "Group",
    "GroupBox",
    "HelpLink",
    "HSplitView",
    "HStack",
    "Image",
    "KeyframeAnimator",
    "Label",
    "LazyHGrid",
    "LazyHStack",
    "LazyVGrid",
    "LazyVStack",
    "LinearGradient",
    "Link",
    "List",
    "Menu",
    "MenuButton",
    "MultiDatePicker",
    "NavigationLink",
    "NavigationSplitView",
    "NavigationStack",
    "NavigationView",
    "NewDocumentButton",
    "PasteButton",
    "Path",
    "PhaseAnimator",
    "Picker",
    "ProgressView",
    "RadialGradient",
    "Rectangle",
    "RenameButton",
    "RoundedRectangle",
    "ScrollView",
    "ScrollViewReader",
    "Section",
    "SecureField",
    "SettingsLink",
    "ShareLink",
    "Shape",
    "SignInWithAppleButton",
    "Slider",
    "Spacer",
    "Stepper",
    "Table",
    "TabView",
    "Text",
    "TextEditor",
    "TextField",
    "TextFieldLink",
    "Toggle",
    "ToolbarItem",
    "ToolbarItemGroup",
    "ToolbarTitleMenu",
    "UnevenRoundedRectangle",
    "ViewThatFits",
    "VSplitView",
    "VStack",
    "WindowVisibilityToggle",
    "ZStack"
];