import React, { useState } from 'react'
import { useUserProjects } from "./Backend.js";
import { menuAction, MenuMain } from './AppMenu.js'

// Styling
import styled from 'styled-components'

// YapContentBuilder components
import { 
    YapPage, 
    YapHeader,
    YapHeaderButton,
    YapNotification,
    YapContextMenu,
    YapContextMenuOption,
    useYapPageState,
    YapUI,
    SegmentedControl
  } from '@yapstudios/yap-content-builder'

import { useAuth } from './Auth.js';
import { Link } from 'react-router-dom';

export function Projects({ projects }) {

    const actions = [
        <YapHeaderButton key="add" icon="add" onClick={() => { console.log('Add Project') }}>{"New Project"}</YapHeaderButton>,
    ]
    
    const projectItems = (projects ?? []).map((project) => {
        return <ProjectItem project={project} key={project.id}/>    
    })

    return (
        <YapPage fullscreen={true} >
            <YapHeader fixed={true} titleLeft={true} largeTitle={false} title={"Designer"} headerActions={actions} headerActionsLeft={[menuAction({ projects: projects })]}></YapHeader>

            <Container>
                <div className="polymer-grid polymer-grid-4-columns">
                    {projectItems}
                </div>
            </Container>

        </YapPage>
    )
}

const Container = styled.div`   
    padding: 100px;
    margin: 0 auto;
    color: black;
`

export function ProjectItem({project}) {
    return (
        <Link to={`/designer/${project.id}`}>
            <ProjectItemContainer>
                <div>
                    <h2>{project.name ?? project.id}</h2>
                    <span className="version">V1.0</span>
                </div>
                <div>
                    <caption>{project.description}</caption>
                </div>
            </ProjectItemContainer>
        </Link>
    )
}   

const ProjectItemContainer = styled.div`    
    height: 200px;
    width: -webkit-fill-available;
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 20px;
    display: flex;  
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    color: black;

    div {
        flex-direction: column;
        display: flex;
    }

    caption {
        color: #888;
        text-align: left;
    }

    .version {  
        font-size: 12px;
        color: #888;
    }
    h2 {
        
        font-weight: 600;
        font-size: 18px;
        margin: 0px;
    }
    &:hover {
        background-color: #eaeaea;
    }
`